import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-hometherapy-goals',
  templateUrl: './hometherapy-goals.component.html',
  styleUrls: ['./hometherapy-goals.component.scss']
})
export class HometherapyGoalsComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private formBuilder: FormBuilder) { }

  data = [];
  search = "";
  limit = 20;
  selectedGoal = {id:0, goal:"", description:""};
  howto = [];
  tutorials = [];

  video = {};
  noVideoText = "Loading video";

  //upload
  file: File = null;
  uploadForm: FormGroup;
  error:string;
  fileUpload = {status: '', message: '', filePath: ''};
  upFiles = [];
  fileTitle = '';


  ngOnInit() {
  	this.loadGoals();

    this.uploadForm = this.formBuilder.group({
      file: [''],
      file_title: "",
    });

    //stop video playback on modal close
    let context = this;
    //@ts-ignore
    $('#modal-player').on('hidden.bs.modal', function (e) {
      //@ts-ignore
      $('#modal-player video').attr("src", '');
      //@ts-ignore
      this.video = {};
      //@ts-ignore
      this.noVideoText = "Loading video";

    });

  }

  loadGoals(){

    /*  
  	this.dataService.getGoalLibrary(this.limit, this.search).subscribe( (data: any[] )=> {
  		this.data = data;
      
      this.selectedGoal = {id:0, goal:"", description:""};
  		
  	});

    */
  }

  submitGoal(data) {
  	

  	

  }

  selectGoal(goal) {
  	this.selectedGoal = goal;
  	

    //get steps

    this.dataService.getHowto(this.selectedGoal.id).subscribe( (data: any[] )=> {
      this.howto = data;
      
      
    });

    this.dataService.getTutorials(this.selectedGoal.id).subscribe( (data: any[] )=> {
      this.tutorials = data;
      console.log(data);
      
      
    });

  }

  editGoal(data) {
  	//@ts-ignore
  	data.goal_id = this.selectedGoal.id;
  	this.dataService.postEditGoalLib(data).subscribe( (res:any) => {
        if(res.status == 0) {
            $('#modal-edit-goal').modal('hide');
            this.loadGoals();
            
            
          } else {
            alert(res.msg);
          }
    });
  	

  }

  submitHowto(data) {
    data.goal_id = this.selectedGoal.id;
     
    //@ts-ignore
    $('#btn-subgoal').attr('disabled', 'true');

    this.dataService.postAddHowto(data).subscribe( (res:any) => {
        //@ts-ignore
        $('#btn-subgoal').removeAttr('disabled');
        if(res.status == 0) {
            //@ts-ignore
            $('#howto-input').val('');
            $('#modal-add-howto').modal('hide');
            this.selectGoal(this.selectedGoal);
            
            
          } else {
            alert(res.msg);
          }
    }); 

  }

  deleteGoal(goal_id) {
  	var cnf = confirm("Are you sure you want to delete this goal?");
  	var data = {"goal_id":goal_id};
  	if (cnf) {
  		this.dataService.postDeleteGoalLib(data).subscribe( (res:any) => {
        if(res.status == 0) {
            
            this.loadGoals();
            
            
          } else {
            alert(res.msg);
          }
    });
  	}

  }

  searchGoals(search) {
  	this.search = search;
  	this.loadGoals();
  }

  loadMore() {
    this.limit +=20;
    this.loadGoals();
  }

  deleteSubgoal(subgoal) {

    var cnf = confirm("Are you sure you want to delete this subgoal?");

    var data = {"sg_id":subgoal.step_id};

    if (cnf) {
      this.dataService.postDeleteHowto(data).subscribe( (res:any) => {
          //@ts-ignore
          
          if(res.status == 0) {
              //@ts-ignore
              
              this.selectGoal(this.selectedGoal);
              
              
            } else {
              alert(res.msg);
            }
      }); 
    }


  }

  loadVideo(id){

    //@ts-ignore
    $('#modal-player').modal();

    this.dataService.getVideo(id).subscribe( (data: any[] ) =>{
        this.video = data;

        //@ts-ignore
        if (data.status == 1) {
          //@ts-ignore
          this.noVideoText = data.msg;
        }

    });
  }


  submitUpload() {
    
    const formData = new FormData();

    if(this.upFiles.length){
       for(let i=0 ; i < this.upFiles.length ; i++)
         formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
     }

    formData.append('file_title', this.fileTitle);
    //@ts-ignore
    formData.append('goal_id', this.selectedGoal.id);



    

    
    //@ts-ignore
    $('#btn-upload').text('Uploading');
    //@ts-ignore
    $('#btn-upload').attr('disabled', 'true');

    this.dataService.postUpload(formData).subscribe( (res:any) => {
      //@ts-ignore
        $('#btn-upload').text('Upload');
        //@ts-ignore
        $('#btn-upload').removeAttr('disabled');        
      
      if(res.status == 0) {
        
         alert(res.msg);
         //@ts-ignore
         $('#modal-upload').modal('hide');

         this.selectGoal(this.selectedGoal);
        
      } else {
        alert(res.msg);
      }

      

     });

   
  }

  onFileTitle(data) {
    this.fileTitle = data;
  }

  onFileSelect(event) {

    
    if(event.target.files.length > 0) {
      
      
      this.uploadForm.get('file').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }

    }
    

  }

}
