import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private dataService: DataService, private formBuilder: FormBuilder) { }
  data:any = {};
  leaves:any = {leaves:[]};
  role = localStorage.getItem('role_id');
  profileForm: FormGroup;

  ngOnInit() {
  	this.loadProfile();
    this.loadLeaves();

    this.profileForm = this.formBuilder.group({
      photo: [''],
      name: "",
      email:"",
      mobile:"",
      about:""
    });

  }

  loadProfile(){
  	this.dataService.getUserProfile().subscribe( (data: any[] )=> {
	  		this.data = data;
	  		
	  		
	  	} );
  }

  loadLeaves(){
    this.dataService.getUserLeaves().subscribe( (data: any[] )=> {
        this.leaves = data;
        
        
      } );
  }

  submitPassword(data) {
  	if(data.new_password === data.cnew_password) {

      $('#btn-pwd').attr('disabled', 'true');
      $('#btn-pwd').text('Submitting');

  		this.dataService.postPassword(data).subscribe( (res:any) => {
        $('#btn-pwd').text('Submit');
        $('#btn-pwd').removeAttr('disabled');
    		if(res.status == 0) {
          alert(res.msg);
          
        } else {
          alert(res.msg);
        }
      });
    		
    	} else {
  		  alert("New password doesn't match.");
  	  }



  }

  submitLeave() {
    var data = {"leave_date": $('#input-leave').val() };
    

    this.dataService.postLeave(data).subscribe( (res:any) => {

      if(res.status == 0) {
        alert(res.msg);
        this.loadLeaves();
        $('#input-leave').val('');
      } else {
        alert(res.msg);
      }


    });

  }


  onName(val) {
    this.profileForm.get('name').setValue(val);
  }
  onEmail(val) {
    this.profileForm.get('email').setValue(val);
  }
  onMobile(val) {
    this.profileForm.get('mobile').setValue(val);
  }
  onAbout(val) {
    this.profileForm.get('about').setValue(val);
  }

  onFileSelect(event) {

    if(event.target.files.length > 0) {

      const file = event.target.files[0];
      this.profileForm.get('photo').setValue(file);

    }

  }


  submitProfile(){
    $('#btn-profile').attr('disabled', 'true');
    $('#btn-profile').text('Submitting');

    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('email', this.profileForm.get('email').value);
    formData.append('mobile', this.profileForm.get('mobile').value);
    formData.append('about', this.profileForm.get('about').value);
    formData.append('photo', this.profileForm.get('photo').value);

    this.dataService.postProfile(formData).subscribe( (res:any) => {
      
      alert(res.msg);

      if(res.status == 0) {
        $('#btn-profile').removeAttr('disabled');
        $('#btn-profile').text('Save');
        $('#profileModal').modal('hide');
        this.loadProfile();
      }


    });

  }

  

}
