import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss']
})
export class FinalComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = {};
  keyword = 'name';
  pesData = [];

  ngOnInit() {
    this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();
    
  }

  loadData(){
    this.dataService.getDieticsFinal(this.id).subscribe( (data: any[] )=> {
  		this.data = data;
  		console.log('data', data);
    
  	} );
  }

  updateTtm(val){
    //@ts-ignore
    this.data.data.ttm_stage = val;
  }

  selectEvent(event){
    //@ts-ignore
    this.data.data.pes_id = event.id;
    //@ts-ignore
    this.data.data.pes_statement = event.name;

    
  }

  onChangeSearch(event){
    
    this.dataService.getPesAc(event).subscribe( (data: any[] )=> {
  		this.pesData = data;
  		
  	} );

  }

  onClearSearch(event){
    //@ts-ignore
    this.data.data.pes_id = 6;
    
  }

  saveForm(){
    //@ts-ignore
    let data = this.data.data;
    data.patient_id = this.id;
    
    this.dataService.postDieticsFinal(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert(res.msg);
      } else {
        alert(res.msg);
      }


    });
    
  }

  submitIntervention(data){
    data.patient_id = this.id;
    data.date = $('#int-date').val();
    
    this.dataService.postDieticsIntervention(data).subscribe( (res:any) => {
      if(res.status == 0) {
        $('#modal-intervention').modal('hide');
        this.loadData();
        //@ts-ignore
        document.getElementById("formInt").reset(); 
      } else {
        alert(res.msg);
      }


    });
  }

}
