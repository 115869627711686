import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-staff-details-view',
  templateUrl: './staff-details-view.component.html',
  styleUrls: ['./staff-details-view.component.scss']
})
export class StaffDetailsViewComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute,) { }
  user:any = {};
  data:any = {}
  more_data:any = [];
  id:any = '0';

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadData();
  }

  loadData(){
    this.dataService.getStaffDetailsWithId(this.id).subscribe( (data: any[] )=> {
      //@ts-ignore
      this.data = data.data;
      //@ts-ignore
      this.more_data = data.more_data;
      //@ts-ignore
      this.user = data.name;
      
    } );
  }

  removeRow(type, i, id){
    this.more_data[type].splice(i, 1);

    if(id !=0) {
      let data = {id:id};

      this.dataService.postDeleteSdMore(data).subscribe( (res:any) => {
        
      });

    }

  }

}
