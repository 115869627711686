import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService) { }

  data = [];
  id = '0';
  patient = [];

  ngOnInit() {

  	this.id = this.route.snapshot.paramMap.get('id');

  	this.loadPatient();

  }

  loadPatient() {
  	this.dataService.getPatientProfile(this.id).subscribe( (data: any[] )=> {
      this.patient = data;
      console.log(this.patient);
    } );
  }

}
