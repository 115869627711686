import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pbs',
  templateUrl: './pbs.component.html',
  styleUrls: ['./pbs.component.scss']
})
export class PbsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = [];
  score = 0;
  submission_id:any = 0;
  ngOnInit() {

    this.submission_id = (this.route.snapshot.paramMap.get('submission') != null) ? this.route.snapshot.paramMap.get('submission') : 0 ;

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

  	this.loadPbs();


  }

  answer(question, val){
  	$('#'+question).find('[value='+val+']').parent().find('a.btn').addClass('btn-outline-primary')
  	$('#'+question).find('[value='+val+']').parent().find('a.btn').removeClass('btn-primary')
  	$('#'+question).find('[value='+val+']').removeClass('btn-outline-primary');
  	$('#'+question).find('[value='+val+']').addClass('btn-primary');

  	this.data[question] = val;
  	//@ts-ignore
  	let data = {patient_id:this.id, question:question, val:val, submission_id:this.submission_id}
	  this.dataService.postPbsAnswer(data).subscribe( (res:any) => {
      if (res.status == 0) {
      	this.score = res.score;
      }
    });

  }

  loadPbs(){

  	this.dataService.getPbs(this.id, this.submission_id).subscribe( (data: any[] )=> {
  		
      this.data = data;
      //@ts-ignore
      this.submission_id = data.submission_id;
      
      //@ts-ignore
      let context = this;
      let score = 0;
      $('p.question').each(function(){
        let item = $(this).attr('id');
        //@ts-ignore
        let elem = $(this).find('a[value='+context.data.selections[item]+']'); 
        elem.addClass('btn-primary');
        elem.removeClass('btn-outline-primary');
        //@ts-ignore
        score += context.data.selections[item];

      });

      this.score = score;
      
    } );
  }

  updateTextField(field, value){
    //@ts-ignore
    let data = {id:this.data.id, field:field, val:value}
    this.dataService.postPbsTextVal(data).subscribe( (res:any) => {
      if (res.status == 0) {
        
      }
    });

  }

  



}
