import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resource-lib',
  templateUrl: './resource-lib.component.html',
  styleUrls: ['./resource-lib.component.scss']
})
export class ResourceLibComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
