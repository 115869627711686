import { Component, OnInit } from '@angular/core';
import { Chart} from 'chart.js'

@Component({
  selector: 'app-growth-chart',
  templateUrl: './growth-chart.component.html',
  styleUrls: ['./growth-chart.component.scss']
})
export class GrowthChartComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

 
}
