import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-patients-today',
  templateUrl: './patients-today.component.html',
  styleUrls: ['./patients-today.component.scss']
})
export class PatientsTodayComponent implements OnInit {
	
	
  	constructor(private dataService: DataService, private location: Location) { }
  	data = [];

  	role_id = '';
  	key:any;
  	limit = 20;
  	search = "";
  	department= 0;
  	departments = [];
  	edit = { 'treatment':'' };
  	role = localStorage.getItem('role_id');
  	date = "";
	loading = 0;
	ngOnInit() {


		//load key from localStrorage
	    this.key = localStorage.getItem('key');
	    this.role_id = localStorage.getItem('role_id');

		this.date = this.dataService.getLocalData("datePatientsToday");

	    this.loadPatients();
	    this.loadDepartments();


	    //datepicker

	    $( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});


	    




	}

	loadPatients() {
		this.loading = 1;
		this.dataService.getPatientsToday(this.limit, this.search, this.date).subscribe( (data: any[] )=> {
	  		this.data = data;
	  		this.loading = 0;
	  		
	  	} );
	}

	loadDepartments() {
		
		this.dataService.getDepts().subscribe( (data: any[] )=> {
	  		this.departments = data;
	  		console.log(data);
	  		
	  		
	  	} );
	}

	searchPatients(search) {
		this.search = search;
		this.loadPatients();
	}

	loadMore() {
		this.limit +=20;
		this.loadPatients();
	}

	filterDepartment(val){
		this.department = val;
		this.loadPatients();
	}

	loadPatient(id){
		this.edit = { 'treatment':'' };
	    this.dataService.getPatientProfile(id).subscribe( (data: any[] )=> {
	    	//@ts-ignore
	      	this.edit = data;
	      	console.log(this.edit);
	      
	      
	    } );
  	}	

  	submitEdit(data){
  		//@ts-ignore
	    data.patient_id = this.edit.patient_id;
	    data.apl_bpl = $('input[name="apl_bpl"]:checked').val();
	    data.gender = $('input[name="gender"]:checked').val();
	    data.employment_status = $('input[name="employment_status"]:checked').val();
	    data.docinstructions = $('input[name="docinstructions"]:checked').val();
	    data.examination = $('input[name="examination"]:checked').val();
	    data.patient_since = $('#editSince').val();
	    data.dob = $('#editDOB').val();

	    var treatments = [];

	    $('ul.edit-treatments li').each(function(){
	      if( $(this).hasClass('active') ) {
	        treatments.push( $(this).text() );
	      }
	    })

	    data.treatments = treatments;

	    $('#btn-edit').text('Submitting');
	    $('#btn-edit').attr('disabled', 'true');
	    
	    
	    
	    this.dataService.postEditPatient(data).subscribe( (res:any) => {
	      $('#btn-edit').text('Submit');
	      $('#btn-edit').removeAttr('disabled');
	      if(res.status == 0){
	        $('#editPatient').modal('hide');
	        this.loadPatients();
	        
	      } else {
	        alert(res.msg);
	      }

	    });
	    
	}

	switchDate(date) {
		if (date !== null) {
			this.date = date;
			this.dataService.setLocalData("datePatientsToday", date);
			this.loadPatients();
		}
	}

}
