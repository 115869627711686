import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = {};
  page = 'Page 1';
  options = {

  	activity: [
		{
			option: 'Active',
			state:0,
		},
		{
  			option: 'Sedentary',
  			state:0,
  		},
  		{
  			option: 'Moderate',
  			state:0,
  		},
  	],

  	food: [
  		{
  			option: 'Vegetarian',
  			state:0,
  		},
  		{
  			option: 'Ovo Vegetarian',
  			state: 0
  		},
  		{
  			option: 'Non-Vegetarian',
  			state: 0
  		},
  		{
  			option: 'Vegan',
  			state: 0
  		},
  		{
  			option: 'Others',
  			state: 0
  		}
  	],
  	living: [
  		{
  			option: 'Rural',
  			state: 0
  		},
  		{
  			option: 'Urban',
  			state: 0
  		},
  		{
  			option: 'Sub-Urban',
  			state: 0
  		}
  	],
  	deworming: [
  		{
  			option: 'Done once a year',
  			state: 0
  		},
  		{
  			option: 'done once in 6 months',
  			state: 0
  		},
  		{
  			option: 'Not done',
  			state: 0
  		},
  	]
  }

  ngOnInit() {

  	//this.changeState(this.options.food, 'Vegan', 1);

  	this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadForm();

    
  	
  }

  loadForm(){
  	this.dataService.getDietics1(this.id).subscribe( (data: any[] )=> {
	  		this.data = data;
        //@ts-ignore
	  		this.changeState(this.options.activity, data.physical_activity, 1);
        //@ts-ignore
	  		this.changeState(this.options.food, data.food_preference, 1);
        //@ts-ignore
	  		this.changeState(this.options.living, data.living_condition, 1);
        //@ts-ignore
	  		this.changeState(this.options.deworming, data.deworming, 1);
	  } );
  }

  changeState(array, option, state) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i]['option'] === option) {
            //return i;
            array[i]['state'] = state;
        }
    }
    return -1;
  }



  updateOption(optArr, option, dbo){
  	for(var i = 0; i < optArr.length; i += 1) {
  		optArr[i]['state'] = 0;
        if(optArr[i]['option'] === option.option) {
            //return i;
            if (optArr[i]['state'] == 0 ) {
            	optArr[i]['state'] = 1;
            } else {
            	optArr[i]['state'] = 0;
            }



            this.data[dbo] = option.option;
            
        }
    }
  }

  

  updateResponse(question, response){
  	this.data[question] = response;
  	
  }

  saveForm(){
  	$('.btn-submit').attr('disabled', 'true');
  	this.dataService.postSaveDietics1(this.data).subscribe( (res:any) => {
      $('.btn-submit').removeAttr('disabled');
      
      if(res.status == 0) {
        alert(res.msg);
        //this.loadForm();
      } else {
        alert(res.msg);
      }


    });

  }


}
