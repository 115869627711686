import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-asd-checklist',
  templateUrl: './asd-checklist.component.html',
  styleUrls: ['./asd-checklist.component.scss']
})
export class AsdChecklistComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id = 0;
  data = [];
  submission_id = null;
  ngOnInit() {
    this.submission_id = this.route.snapshot.paramMap.get('submission');
  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();
  }

  loadData(){
  	this.dataService.getAsd(this.id, this.submission_id).subscribe( (data: any[] )=> {
  		
      this.data = data;
      
    } );
  }

  toggleCheckbox(item){
    
  	//@ts-ignore
  	if (this.data.data[item] == 0 ) {
  		//@ts-ignore
  		this.data.data[item] = 1;
  	} else {
  		//@ts-ignore
  		this.data.data[item] = 0;
  	}

  	var data = {
  		patient_id: this.id,
  		item: item,
      submission_id: this.submission_id,
  		//@ts-ignore
  		value: this.data.data[item]
  	}
    
    console.log(JSON.stringify(data));

  	this.dataService.postUpdateAsd(data).subscribe( (res:any) => {
        if(res.status == 0) {
            this.submission_id = res.submission_id;
            this.loadData();
          } else {
            alert(res.msg);
          }
      } );

  }

  toggleCheckboxMultiple(item, val){
    console.log("check");
  	//@ts-ignore
  	this.data.data[item] = val;

  	var data = {
  		patient_id: this.id,
  		item: item,
  		//@ts-ignore
  		value: val,
      submission_id: this.submission_id,
  	}

  	this.dataService.postUpdateAsd(data).subscribe( (res:any) => {
        if(res.status == 0) {
            this.loadData();
          } else {
            alert(res.msg);
          }
      } );

  }

}
