import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js'
import {DataService} from '../../../data.service'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-grow-chart',
  templateUrl: './grow-chart.component.html',
  styleUrls: ['./grow-chart.component.scss']
})
export class GrowChartComponent implements OnInit {
  id:any;
  age!: number;
 height!: number;
 gctx:any;
 myChart:any;
 data= [];
 sGraph!: any;
 labels: any;
 t1:any;
 t2:any;
  graphList = [
      {num: 0, n: "Height For Age",t1:"Age (Months)",t2:"Height (cm)"},
      {num: 1, n: "Weight For Age",t1:"Age (Months)",t2:"Weight"},
      {num: 2, n: "BMI For Age",t1:"Age (Months)",t2:"BMI"},
      {num: 3, n: "Head Circumference For Age",t1:"Age (Months)",t2:"Head Circumference"}

  ];
  datasets:any;
  graphData: any;
  result: any[];
  
 constructor(private router:Router, private route: ActivatedRoute, private dataService:DataService) {}
  selectGraph(){
    this.LoadData();
    for(var i=0;i<this.graphList.length;i++){
      if(this.graphList[i].num==this.sGraph){
        this.t1=this.graphList[i].t1;
        this.t2=this.graphList[i].t2;
      }
    }
    
  }

  ngOnInit(): void {
    //this.labels= [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48];
   
    this.sGraph = 0;
    this.t1 = 'Age (months)';
    this.t2 = 'Height (cm)';
    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

   this.drawGraph1();
   this.LoadData();
   

  }
 LoadData(){
     let context = this;
     this.data = [];
    this.dataService.getGraphData(this.id,this.sGraph).subscribe((data: any )=> {
       this.result=data;
       //@ts-ignore
       this.datasets = data.ref_data;
       this.labels = data.labels;
       this.drawGraph1();
       if (data.graph_data.length > 0) {
         data.graph_data.forEach(function(k, i){
           context.data.push({id:k.id, x:k.x, y:k.y});
         });
       } else {
         this.data.push({ id:0, x:0, y:0});
       }

       
         
       this.addData(this.myChart,this.data);

    })
 }
    

  addData(chart: { data: { datasets: { label: string; borderColor: string; fill:boolean; data: any; }[]; }; update: () => void; }, data: { x: number; y: number; }[]) {
		chart.data.datasets.push({
	    label: 'Child',
        borderColor: "red",
        fill:false,
      data: data
    });
    chart.update();

 }
add(){

  if (this.data.length == 1 && this.data[0].x == 0 && this.data[0].y == 0 ) {
    this.data = [];
  }

    this.data.push({id:0, x:this.age, y:this.height});
    this.addData(this.myChart,this.data);
    this.graphData={"patient_id":this.id, "graph_type":this.sGraph, "x":this.age, "y":this.height};
    this.saveGraphData();
    this.age = null;
    this.height = null;
    

}
drawGraph1(){
  var chartData = {
    labels: this.labels,
    datasets:this.datasets
  };
  
  var originalController = Chart.controllers.line;
  Chart.controllers.line = Chart.controllers.line.extend({
    draw: function() {
      originalController.prototype.draw.call(this, arguments);
      drawLabels(this);
    }
  });
  
  function drawLabels( t: { chart: any; } | undefined) {
    if(ctx){
      ctx.save();
    ctx.font = Chart.helpers.fontString(12, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
    ctx.fillStyle = 'red';
    ctx.textBaseline = 'bottom'; 
  if(t){
    var chartInstance = t.chart;
  
    var datasets = chartInstance.config.data.datasets;
  }
    datasets.forEach((ds: { label: any; }, index: any) => {
      var label = ds.label;
      var meta = chartInstance.controller.getDatasetMeta(index);
      var len = meta.data.length-1;
      //console.log(ds, meta.data[len]._model);    
      var xOffset = meta.data[len]._model.x+10;
      var yOffset = meta.data[len]._model.y;
      if(ctx)
      ctx.fillText(label, xOffset, yOffset);
    });
    ctx.restore();
}

  }
  
  const ctx1 = <HTMLCanvasElement> document.getElementById('myChart');
var ctx = ctx1.getContext('2d');
this.gctx=ctx;
if(ctx){
   if (this.myChart) {
     this.myChart.destroy();
   }

   console.log('cdata', chartData);
   this.myChart = new Chart(ctx, {
    type: 'line',
    data: chartData,
    options: {
        legend: { display: false },
      scales: {
       /* xAxes: [{
            type: 'linear',
          scaleLabel: { display: true, labelString: 'x' }
        }],*/
        yAxes: [{
          ticks: { min: 0 },
          scaleLabel: { display: true}
        }, {
          
          position: 'right',
          ticks: { min: 0,max:120 },
          scaleLabel: { display: true}
        }]
      },
      layout: {
        padding: {
          left: 0,
          right: 60,
          top: 20,
          bottom: 0
        }
      },
      /*elements: {
          point:{
              radius: 0
          }
      }*/
    }
  });
}
}

  saveGraphData(){
    //alert(this.data);
    this.dataService.postGraphData(this.graphData).subscribe( (res:any) => {
      
      if(res.status==0){
        this.data[this.data.length-1].id = res.id;
      }
      else{
        alert(res.msg)
      }
    })
  }

  deleteGraphData(id, index){
    console.log('id-', id, ',index-', index);
    let cnf = confirm("Do you really want to delete this entry?");

    if(cnf){

      if(id != 0) {
        let data = {id:id};
        this.dataService.postDeleteGraphData(data).subscribe( (res:any) => {
          console.log('del', res);
        })
      }
      
      this.data.splice(index,1);

      if(this.data.length == 0) {
        this.data.push({ id:0, x:0, y:0});
      }

      this.addData(this.myChart,this.data);
    }

  }

}
