import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adult-assessment',
  templateUrl: './adult-assessment.component.html',
  styleUrls: ['./adult-assessment.component.scss']
})
export class AdultAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
