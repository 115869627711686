import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-indt',
  templateUrl: './indt.component.html',
  styleUrls: ['./indt.component.scss']
})
export class IndtComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  data:any = {status:0, previous:{id:0}, next:{id:0}, section:{id:0}, questions:[]};
  id = 0;

  ngOnInit() {

    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData(0);
  }

  loadData(section_id){
    this.dataService.getIndt(section_id, this.id).subscribe( (data: any[] )=> {
  		this.data = data;
  		
  	} );
  }

  switchSection(section_id){

    this.loadData(section_id)

  }

  saveResponse(question, response){

    var data = {patient_id:this.id, section_id:this.data.section.id, question_id:question.id, response:response};
    question.response = response;

    this.dataService.postSaveIndt(data).subscribe( (res:any) => {
      
      if(res.status == 0) {
        //alert(res.msg);
        
        if(this.data.section.id == 8) {
          this.loadData(this.data.section.id);
        }

      } else {
        alert(res.msg);
      }


    });

  }

  saveComments(){
    var data = {patient_id:this.id, comment: this.data.comment};
    $(".btn-save-comment").text("Saving comments ...");
    this.dataService.postSaveIndtComment(data).subscribe( (res:any) => {
      $(".btn-save-comment").text("Saved").delay(1000).text("Save comments");
      if(res.status == 0) {
        //alert(res.msg);
        

      } else {
        alert(res.msg);
      }


    });

  }


}
