import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

	//group = [ {"question":"", "response":""},  ];
	//emptyRow = {"question":"", "response":""};

	  id= 0;
    form_id = '0';
    submission_id = null;
  	role_id = '0';
  	forms = [];
  	selectedForm = {id:0};
    hbm = 0;
    hbdata = [];
    hbsession = [];
    scrollTrigger = 0;

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {
  	this.role_id = localStorage.getItem('role_id');
  	
  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.form_id = this.route.snapshot.paramMap.get('formid');
    this.submission_id = this.route.snapshot.paramMap.get('submission');
    

    this.loadForms(this.form_id);
    

    
  }

  
  

  loadForms(form_id){
  	this.dataService.getAssessmentForms().subscribe( (data: any[] )=> {
	  		this.forms = data;
	  		
	  		//@ts-ignore
	  		if (data.forms.length > 0) {
	  			//@ts-ignore
		        if (form_id == 0) {
		            //@ts-ignore
		            this.loadForm(data.forms[0].id);
		        } else {
		            this.loadForm(form_id);
		        }
	  			
	  		}

	  } );
  }

  loadForm(form_id) {
  	this.dataService.getAssessmentForm(form_id, this.id, this.submission_id).subscribe( (data: any[] )=> {
        //@ts-ignore
	  		this.selectedForm = data;
        //@ts-ignore
	  		this.hbdata = data.hbdata;
      
	  });
	  
  }

  scrollEvent(){
    if(this.scrollTrigger == 0) {
      if(this.hbdata.length > 0) {
        this.plotHumanBody();
        this.scrollTrigger = 1;
      }
    }
    
  }

  selectOption(question, option) {
  	//@ts-ignore
	if (this.selectedForm.data[question].options[option].state == 1) {
		//@ts-ignore
  		this.selectedForm.data[question].options[option].state = 0;
  	} else {
  		//@ts-ignore
  		var context = this;
      //@ts-ignore
      if (this.selectedForm.data[question].is_multiselect == 0) {
        //@ts-ignore
        this.selectedForm.data[question].options.forEach(function(i, k) {
          i.state = 0;
        })
      }

  		
  		//@ts-ignore
  		this.selectedForm.data[question].options[option].state = 1;
  	}

  	
  }

  updateResponse(question, option, value) {
    if (value.length > 0) {
      //@ts-ignore
      this.selectedForm.data[question].options[option].state = 1;
    } else {
      //@ts-ignore
      this.selectedForm.data[question].options[option].state = 0;
    }
    //@ts-ignore
    this.selectedForm.data[question].options[option].response = value;

  }

  updateTableResponse(question, tquestion, option, value) {
    if (value.length > 0) {
      //@ts-ignore
      this.selectedForm.data[question].table_data.questions[tquestion].trdata[option].response = value;
    } else {
      
      
    }
    
    

  }

  saveForm() {
    $('.btn-submit').attr('disabled', 'true');
    var data = {"patient_id":this.id, submission_id:this.submission_id, "form":this.selectedForm};
    
    this.dataService.postSaveAssessmentForm(data).subscribe( (res:any) => {
      $('.btn-submit').removeAttr('disabled');
      
      if(res.status == 0) {
        alert(res.msg);
        //@ts-ignore
        this.router.navigate(['/dashboard/patient/'+this.id+'/form/'+this.form_id+'/edit/'+res.submission_id]);
        //this.loadForm(this.selectedForm.id);

      } else {
        alert(res.msg);
      }


    });
    

  }

  markSurvey(question, option) {
    
    let context = this;
    //@ts-ignore
    this.selectedForm.survey_questions[question].responses.forEach(function(item, index){
      //@ts-ignore
      context.selectedForm.survey_questions[question].responses[index].value = 0;
    });  
    
    //@ts-ignore
    this.selectedForm.survey_questions[question].responses[option].value = 1;

    let data = {
      patient:this.id,
      form_id:this.form_id,
      submission_id: this.submission_id,
      //@ts-ignore
      question:this.selectedForm.survey_questions[question].id,
      //@ts-ignore
      option: this.selectedForm.survey_questions[question].responses[option].id
      
    };

    this.dataService.postSurveyResponse(data).subscribe( (res:any) => {
      
      //@ts-ignore
      this.submission_id = res.submission_id;
      
    });

    //console.log('question', this.selectedForm.survey_questions[question].id);
  }

  toggleMarking(val){
    this.hbm = val;
    if(val == 0) {

      if(this.hbsession.length > 0) {
        let data = {patient_id: this.id, data:this.hbsession, form_id:this.form_id};
        
        this.dataService.postHbData(data).subscribe( (res:any) => {
          
          
        });
      }

      

      this.hbsession = [];
    }
  }

  canvasEvent(event) {
    
    if(this.hbm == 1) {
      //@ts-ignore
      var rect = humanbody.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;

      this.hbsession.push([x,y]);
      this.drawCoordinates(this.hbsession);
      

    }

  }

  plotHumanBody(){
    
    let context = this;
    this.hbdata.forEach(function(i, k){
      
      context.drawCoordinates(i.data);
    });
  }

  drawCoordinates(session){

    if(this.hbsession.length == 1) {
      var pointSize = 2; // Change according to the size of the point.
      //@ts-ignore
      var ctx = document.getElementById("humanbody").getContext("2d");
      

      ctx.fillStyle = "#ff2626"; // Red color

      ctx.beginPath(); //Start path
      ctx.arc(session[0][0], session[0][1], pointSize, 0, Math.PI * 2, true); // Draw a point using the arc function of the canvas with a point structure.
      ctx.fill(); // Close the path and fill.
    } else {
      //@ts-ignore
      var ctx = document.getElementById("humanbody").getContext("2d");
      

      ctx.beginPath();
      ctx.strokeStyle = "#ff2626"; // Red color
	    ctx.lineWidth = 1;
      ctx.moveTo(session[0][0], session[0][1]);
      session.forEach(function(i, k){
        
        ctx.lineTo(i[0], i[1]);
        ctx.stroke();
      });
    }

    
}
	

}
