import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.scss']
})
export class SubmissionsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  forms:any = {status:0, data:[]};

  ngOnInit() {
    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();

  }

  loadData(){
    this.dataService.getFormSubmissions(this.id).subscribe( (data: any[] )=> {
      this.forms = data;
      console.log('forms', data);
      

    });
  }

  openSubmission(item){
    let url = "";
    if(item.form_id == 0){

      switch(item.form_slug){
        case "asd-checklist":
          url = "/dashboard/patient/"+this.id+"/"+item.form_slug+"/edit/"+item.id;
          break;
          case "pbs":
            url = "/dashboard/patient/"+this.id+"/"+item.form_slug+"/edit/"+item.id;
            break;
        case "audio-evaluation":
          url = "/dashboard/patient/"+this.id+"/"+item.form_slug+"/"+item.test_id;
          break;
        default:
          url = "/dashboard/patient/"+this.id+"/"+item.form_slug;  
          break;
      }


      
    } else {
      
      url = '/dashboard/patient/'+this.id+'/form/'+item.form_id+'/edit/'+item.id;
    }

    this.router.navigate([url]);

  }



}
