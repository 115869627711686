import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-anthropometric',
  templateUrl: './anthropometric.component.html',
  styleUrls: ['./anthropometric.component.scss']
})
export class AnthropometricComponent implements OnInit {


  id = 0;
  data = {};

  form = {
  	height:'',
  	kh: '',
  	ual:'',
  	tl:'',
  	weight: '',
  	bmi:'',
  	bmi_in:'',
  	body_fat:'',
  	abdominal:'',
  	triceps:'',
  	thigh:'',
  	supra_iliac:'',
  	visceral_fat:'',
  	waist_hip_ratio:'',
  	waist_circum:'',
  	hip_circum:'',
  	muscle_mass:'',
  	resting_mb:''
  };

  selectedItem = {
	  	id:0,
	  	height:'',
		kh: '',
		ual:'',
		tl:'',
		weight: '',
		bmi:'',
		bmi_in:'',
		body_fat:'',
		abdominal:'',
		triceps:'',
		thigh:'',
		supra_iliac:'',
		visceral_fat:'',
		waist_hip_ratio:'',
		waist_circum:'',
		hip_circum:'',
		muscle_mass:'',
		resting_mb:''
	};


  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {
  	this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });


  	this.loadData();

  }


  calculateHeight(mode, value){
  	let height = 0;
  	value = Number(value);

  	if (value != 0) {
  		switch (mode) {
	  		case "kh":
	  			height = (2.69 * value) + 24.2;
	  			break;

	  		case "ual":
	  			height = (4.35 * value) + 21.8;
	  			break;

	  		case "tl":
	  			height = (3.26 * value) + 30.8;
	  			break;
	  		
	  		default:
	  			// code...
	  			break;
	  	}

	  	this.form.height = height.toFixed(2);
  	}



  	

  }

  calculateEditHeight(mode, value){
	let height = 0;
	value = Number(value);

	if (value != 0) {
		switch (mode) {
			case "kh":
				height = (2.69 * value) + 24.2;
				break;

			case "ual":
				height = (4.35 * value) + 21.8;
				break;

			case "tl":
				height = (3.26 * value) + 30.8;
				break;
			
			default:
				// code...
				break;
		}
		//@ts-ignore
		this.selectedItem.height = height.toFixed(2);
	}



	

}

  calculateBMI(weight){
  		let bmi = 0;
  		weight = Number(weight)
  		let height = Number(this.form.height);
  		if (height > 0 && weight > 0) {
  			height = height/100;

  			bmi = (weight) / (height * height);
  			this.form.bmi = bmi.toFixed(1);

  			if (bmi < 18.5) {
  				this.form.bmi_in = "Underweight";
  			} else if (bmi >= 18.5 && bmi <= 22.9) {
  				this.form.bmi_in = "Normal or Lean";
  			} else if (bmi >= 23.0 && bmi <= 24.9) {
  				this.form.bmi_in = "Overweight";
  			} else if (bmi >= 25) {
  				this.form.bmi_in = "Obese";
  			}

  		}

  	}

	calculateEditBMI(weight){
		
		let bmi = 0;
		weight = Number(weight)
		let height = Number(this.selectedItem.height);
		if (height > 0 && weight > 0) {
			height = height/100;

			bmi = (weight) / (height * height);
			this.selectedItem.bmi = bmi.toFixed(1);
			console.log("bmi", bmi);

			if (bmi < 18.5) {
				this.selectedItem.bmi_in = "Underweight";
			} else if (bmi >= 18.5 && bmi <= 22.9) {
				this.selectedItem.bmi_in = "Normal or Lean";
			} else if (bmi >= 23.0 && bmi <= 24.9) {
				this.selectedItem.bmi_in = "Overweight";
			} else if (bmi >= 25) {
				this.selectedItem.bmi_in = "Obese";
			}

		}

	}

  	calculateBodyFat(){
  		let bf = 0;
  		let abdominal = Number(this.form.abdominal);
  		let triceps = Number(this.form.triceps);
  		let thigh = Number(this.form.thigh);
  		let supra_iliac = Number(this.form.supra_iliac);

  		if (abdominal > 0 && triceps > 0 && thigh > 0 && supra_iliac > 0) {

  			let sum = abdominal + triceps + thigh + supra_iliac;
  			//@ts-ignore
  			if (this.data.gender == "Male") {
				//@ts-ignore  				
  				bf = (0.29288 * sum ) - (0.0005 * (sum*sum)) + (0.15845 * this.data.age) - 5.76377;
  				//@ts-ignore
  			} else if (this.data.gender == "Female") {
  				//@ts-ignore  				
  				bf = (0.29699 * sum ) - (0.00043 * (sum*sum)) + (0.02963 * this.data.age) + 1.4072;
  			}

  			this.form.body_fat = bf.toFixed(2);	
  		}

  		

  		
  	}

	  calculateEditBodyFat(){
		let bf = 0;
		let abdominal = Number(this.selectedItem.abdominal);
		let triceps = Number(this.selectedItem.triceps);
		let thigh = Number(this.selectedItem.thigh);
		let supra_iliac = Number(this.selectedItem.supra_iliac);

		if (abdominal > 0 && triceps > 0 && thigh > 0 && supra_iliac > 0) {

			let sum = abdominal + triceps + thigh + supra_iliac;
			//@ts-ignore
			if (this.data.gender == "Male") {
			  //@ts-ignore  				
				bf = (0.29288 * sum ) - (0.0005 * (sum*sum)) + (0.15845 * this.data.age) - 5.76377;
				//@ts-ignore
			} else if (this.data.gender == "Female") {
				//@ts-ignore  				
				bf = (0.29699 * sum ) - (0.00043 * (sum*sum)) + (0.02963 * this.data.age) + 1.4072;
			}

			this.selectedItem.body_fat = bf.toFixed(2);	
		}

		

		
	}

    calculateWHR(){
      let whr = 0;
      let waist = Number(this.form.waist_circum);
      let hip = Number(this.form.hip_circum);

      if (waist > 0 && hip > 0) {
        whr = waist/hip
        this.form.waist_hip_ratio = whr.toFixed(2);
      }



    }

	calculateEditWHR(){
		let whr = 0;
		let waist = Number(this.selectedItem.waist_circum);
		let hip = Number(this.selectedItem.hip_circum);
  
		if (waist > 0 && hip > 0) {
		  whr = waist/hip
		  this.selectedItem.waist_hip_ratio = whr.toFixed(2);
		}
  
  
  
	  }


  	loadData(){
  		this.dataService.getDieticsAM(this.id).subscribe( (data: any[] )=> {
	  		this.data = data;
	  		console.log('data', data);
	    
	  	} );
  	}


    submitEntry(data){
      data.patient_id = this.id;

      this.dataService.postDieticsAM(data).subscribe( (res:any) => {
        if(res.status == 0) {
          $('#modal-entry').modal('hide');
          //@ts-ignore
          document.getElementById("formEntry").reset(); 
          this.loadData();
        } else {
          alert(res.msg);
        }


      });


    }

	submitEdit(data){
		data.id = this.selectedItem.id;
  
		this.dataService.postEditDieticsAM(data).subscribe( (res:any) => {
		  if(res.status == 0) {
			$('#modal-edit').modal('hide');
			//@ts-ignore
			//document.getElementById("formEntry").reset(); 
			//this.loadData();
		  } else {
			alert(res.msg);
		  }
  
  
		});
  
  
	  }

    expandBox(item){
		//@ts-ignore
		this.data.data.forEach(function(k, i){
			k.state = 0;
		});

		item.state = 1;
    }

	selectItem(item){
		item.state = 1;
		this.selectedItem = item;
		console.log(item);
	}


}
