import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disability',
  templateUrl: './disability.component.html',
  styleUrls: ['./disability.component.scss']
})
export class StatisticsDisabilityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	this.plotDisabilities();
  }

  plotDisabilities(){

  	//@ts-ignore
  	var ctx = document.getElementById('disability-graph').getContext('2d');
    //@ts-ignore
  	var chart = new Chart(ctx, {
  		type:'line',
  		data: {
  			labels: ['2015', '2016', '2017', '2018', '2019'],
  			datasets: [{
  				backgroundColor: 'orange' ,
	            borderColor: 'orange',
	            data: [1200,1130,1300,1220,1250],
	            label: 'Intellectual disability',
	            fill:false,
  			},
  			{
  				backgroundColor: 'blue' ,
	            borderColor: 'blue',
	            data: [800,850,920,1080,1050],
	            label: 'Autism spectrum disorders',
	            fill:false,
  			},
  			
  			{
  				backgroundColor: 'indigo' ,
	            borderColor: 'indigo',
	            data: [ 1150, 1300, 1180, 1265, 1275 ],
	            label: 'Behavior issue',
	            fill:false,
  			},
  			{
  				backgroundColor: 'green' ,
	            borderColor: 'green',
	            data: [ 700, 750, 720, 670, 710 ],
	            label: 'Speech & language impairment',
	            fill:false,
  			},

  			{
  				backgroundColor: 'red' ,
	            borderColor: 'red',
	            data: [ 400, 600, 620, 600, 800],
	            label: 'Global development delay',
	            fill:false,
  			},

        {
          backgroundColor: '#C93953' ,
              borderColor: '#C93953',
              data: [200, 400, 390, 380, 220],
              label: 'Vision impairment',
              fill:false,
        },
        {
          backgroundColor: '#8A8643' ,
              borderColor: '#8A8643',
              data: [600, 500, 550, 520, 620],
              label: 'Hearing impairment',
              fill:false,
        },
        {
          backgroundColor: '#7D303E' ,
              borderColor: '#7D303E',
              data: [99, 110, 220, 190, 100],
              label: 'Congenital talipes equinovarus',
              fill:false,
        },

  			],
  			

  		},
  		options: {
	    	legend: {
	            display: true,
              position: 'right'
	        },
	        scales: {
		        yAxes: [{
		            ticks: {
		                beginAtZero: true
		            }
		        }]
		    }
	    }
  	});

  }

}
