import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-eat10',
  templateUrl: './eat10.component.html',
  styleUrls: ['./eat10.component.scss']
})
export class Eat10Component implements OnInit {

	id = 0;
	form_id = 88;
	selectedForm = {};
	page = 'Page 3';

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {
  	this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadForm(this.form_id);

  }

  loadForm(form_id) {
  	this.dataService.getAssessmentForm(form_id, this.id, null).subscribe( (data: any[] )=> {
        //@ts-ignore
	  		this.selectedForm = data;
	  		console.log('form', data);

	  });
	  
  }

  markSurvey(question, option) {
    let context = this;
    //@ts-ignore
    this.selectedForm.survey_questions[question].responses.forEach(function(item, index){
      //@ts-ignore
      context.selectedForm.survey_questions[question].responses[index].value = 0;
    });  
    
    //@ts-ignore
    this.selectedForm.survey_questions[question].responses[option].value = 1;

    let data = {
      patient:this.id,
      //@ts-ignore
      question:this.selectedForm.survey_questions[question].id,
      //@ts-ignore
      option: this.selectedForm.survey_questions[question].responses[option].id,
      submission_id:"eat10",
    };

    this.dataService.postSurveyResponse(data).subscribe( (res:any) => {
      console.log(res);
      
    });

    //console.log('question', this.selectedForm.survey_questions[question].id);
  }

  saveForm() {
    $('.btn-submit').attr('disabled', 'true');
    var data = {"patient_id":this.id, "form":this.selectedForm, submission_id:"eat10"};
    console.log('data-form', data);
    this.dataService.postSaveAssessmentForm(data).subscribe( (res:any) => {
      $('.btn-submit').removeAttr('disabled');
      console.log(res);
      if(res.status == 0) {
        alert(res.msg);
        //@ts-ignore
        this.loadForm(this.selectedForm.id);
      } else {
        alert(res.msg);
      }


    });
    

  }

}
