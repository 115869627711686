import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-bill-heads',
  templateUrl: './bill-heads.component.html',
  styleUrls: ['./bill-heads.component.scss']
})
export class BillHeadsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = [];
  is_therapy = 1;
  selectedHead:any = {title:"", amount:0, is_therapy:0};
  depts:any = [];
  categories:any = [];

  ngOnInit() {
    this.loadData();
    this.loadDepartments();
    this.loadCategories();
    
  }

  loadData(){
    this.dataService.getBillHeads(2).subscribe( (data: any[] )=> {
      this.data = data;
    });
  }

  loadDepartments(){
    this.dataService.getDepts().subscribe( (data: any = {status:0, departments:[]} )=> {
      if(data.status == 0){
        this.depts = data.departments;
      }
      
    });
  }

  loadCategories(){
    this.dataService.getBillCategories().subscribe( (data: any = [] )=> {
      this.categories = data;
      
    });
  }

  submitNew(data){
    this.dataService.postBillHead(data).subscribe( (res:any) => {
      alert(res.msg);

      if(res.status==0){
        this.loadData();
        $("#modal-add-head").modal("hide");
        //@ts-ignore
        document.getElementById("formNew").reset();
      }

    }); 
  }

  selectHead(item){
    this.selectedHead = item;
  }

  submitEdit(data){
    data.id = this.selectedHead.id;
    this.dataService.postEditBillHead(data).subscribe( (res:any) => {
      alert(res.msg);
      if(res.status==0){
        this.loadData();
        $("#modal-edit-head").modal("hide");
      }

    }); 
  }

  deleteHead(item){
    var cnf = confirm("Do you really want to delete this item?");

    if(cnf){
      var data = {id:item};
      
      this.dataService.postDeleteBillHead(data).subscribe( (res:any) => {
        alert(res.msg);
        if(res.status==0){
          this.loadData();
          
        }
  
      }); 
    }

  }

}
