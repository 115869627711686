import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-audio-evaluation',
  templateUrl: './audio-evaluation.component.html',
  styleUrls: ['./audio-evaluation.component.scss']
})
export class AudioEvaluationComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
 
  id = 0;
  test = '0';
  data:any = {status:0, title:""};
  postData = [];
  formTitles=["Puretone Audiogram", "Behaviourial Observational Audiometry", "Conditional Audiometry"];
  formTitle="Puretone Audiogram";
  

  ngOnInit() {
    //get patient_id
    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];
      
    });

    //get test_id

    if(this.route.snapshot.paramMap){
      console.log('ues');
      this.test = this.route.snapshot.paramMap.get('test');
    }
    
    this.loadData();

    

  }

  loadData(){
    //@ts-ignore
    this.dataService.getAudioEval(this.id, this.test).subscribe( (data: any[] )=> {
  		//@ts-ignore
      if(this.test == 0 || this.test == null) {
        //@ts-ignore
        this.router.navigate(['/dashboard/patient/'+this.id+'/audio-evaluation/'+data.test_id]);
      }
      //this.test = data.test_id;
      //@ts-ignore
      this.data = data;
      this.postData = data;
      this.formTitle = (this.data.title != null ) ? this.data.title : this.formTitles[0] ;
      this.plotGraph();
    } );
  }

  plotGraph(){

    

    //@ts-ignore
    var rightac = this.data.aright;
    //@ts-ignore
    var rightbc = this.data.bright;
    //@ts-ignore
    var leftac = this.data.aleft;
    //@ts-ignore
    var leftbc = this.data.bleft;
    
    

    if(this.data.masked.ac_right_res == true  ) {
      var right_ac = 'url(../../../../assets/img/ac_nores_right.png)'
    } else if(this.data.masked.ac_right == 2){
      var right_ac = 'url(../../../../assets/img/bc_forehead_right.png)'
    } else if(this.data.masked.ac_right == 3){
      var right_ac = 'url(../../../../assets/img/unmasked_sound_right.png)'
    } else {
      var right_ac = (this.data.masked.ac_right == 0) ? 'url(../../../../assets/img/circle.png)' :  'url(../../../../assets/img/AC_Masked_Right.png)';
    }
    
    if(this.data.masked.bc_right_res == true  ) {
      var right_bc = 'url(../../../../assets/img/bc_nores_right.png)'
    } else if(this.data.masked.bc_right == 2){
      var right_bc = 'url(../../../../assets/img/bc_forehead_right.png)'
    } else if(this.data.masked.bc_right == 3){
      var right_bc = 'url(../../../../assets/img/unmasked_sound_right.png)'
    } else {
      var right_bc = (this.data.masked.bc_right == 0) ?  'url(../../../../assets/img/BC Mastoid_Unmasked_Right.png)' : 'url(../../../../assets/img/BC Mastoid_Masked_Right.png)';
    }
    
    if(this.data.masked.ac_left_res == true  ) {
      var left_ac = 'url(../../../../assets/img/ac_nores_left.png)'
    } else if(this.data.masked.ac_left == 2){
      var left_ac = 'url(../../../../assets/img/bc_forehead_left.png)'
    } else if(this.data.masked.ac_left == 3){
      var left_ac = 'url(../../../../assets/img/unmasked_sound_left.png)'
    } else {
      var left_ac =  (this.data.masked.ac_left == 0) ? 'url(../../../../assets/img/asterisk.png)' : 'url(../../../../assets/img/AC_Masked_Left.png)' ;
    }

    if(this.data.masked.bc_left_res == true  ) {
      var left_bc = 'url(../../../../assets/img/bc_nores_left.png)'
    } else if(this.data.masked.bc_left == 2){
      var left_bc = 'url(../../../../assets/img/bc_forehead_left.png)'
    } else if(this.data.masked.bc_left == 3){
      var left_bc = 'url(../../../../assets/img/unmasked_sound_left.png)'
    } else {
      var left_bc =  (this.data.masked.bc_left == 0) ? 'url(../../../../assets/img/BC Mastoid_Unmasked_Left.png)' : 'url(../../../../assets/img/BC Mastoid_Masked_Left.png)' ;
    }
    
    
    
    

    //Right

    //@ts-ignore
    $('#gright').highcharts({
        title: {
            text: 'Right',
            x: -20 //center
        },
        
        xAxis: {
          categories: ['125 Hz','250 Hz', '500 Hz', '750 Hz', '1k Hz', '2k Hz', '3k Hz', '4k Hz', '6k Hz', '8k Hz']
        },
        yAxis: {
            reversed: true,
            showFirstLabel: false,
            title: {
                text: 'dB',
                reversed: true,
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#FF0000'
            }]
        },
        tooltip: {
            
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            borderWidth: 0
        },
        series: [{
            name: 'Right AC',
            color: '#c80202',
            marker: {
                symbol: right_ac
            },
            data: [Number(rightac.acr1), Number(rightac.acr2), Number(rightac.acr3), Number(rightac.acr4), Number(rightac.acr5), Number(rightac.acr6), Number(rightac.acr7), Number(rightac.acr8), Number(rightac.acr9), Number(rightac.acr10)]
        },{
            name: 'Right BC',
            color: '#c80202',
            marker: {
                symbol: right_bc
            },
            data: [Number(rightbc.bcr1),Number(rightbc.bcr2), Number(rightbc.bcr3), Number(rightbc.bcr4), Number(rightbc.bcr5), Number(rightbc.bcr6), Number(rightbc.bcr7), Number(rightbc.bcr8), Number(rightbc.bcr9), Number(rightbc.bcr10)],
            dashStyle:'dot'
        }]
    });
    console.log('plotting graph');
    //Left
    //@ts-ignore
    $('#gleft').highcharts({
        title: {
            text: 'Left',
            x: -20 //center
        },
        
        xAxis: {
            categories: ['125 Hz','250 Hz', '500 Hz', '750 Hz', '1k Hz', '2k Hz', '3k Hz', '4k Hz', '6 Hz', '8k Hz']
        },
        yAxis: {
            reversed: true,
            showFirstLabel: false,
            title: {
                text: 'dB',
                reversed: true,
            },
            plotLines: [{
                value: 0,
                width: 1,
                color: '#FF0000'
            }]
        },
        tooltip: {
            
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
            borderWidth: 0
        },
        series: [{
            name: 'Left AC',
            color: '#000f7e',
            marker: {
                symbol: left_ac
            },
            data: [Number(leftac.acl1),Number(leftac.acl2), Number(leftac.acl3), Number(leftac.acl4), Number(leftac.acl5), Number(leftac.acl6), Number(leftac.acl7), Number(leftac.acl8), Number(leftac.acl9), Number(leftac.acl10)]
        },{
            name: 'Left BC',
            color: '#000f7e',
            marker: {
                symbol: left_bc
            },
            data: [Number(leftbc.bcl1),Number(leftbc.bcl2), Number(leftbc.bcl3), Number(leftbc.bcl4), Number(leftbc.bcl5), Number(leftbc.bcl6), Number(leftbc.bcl7), Number(leftbc.bcl8), Number(leftbc.bcl9), Number(leftbc.bcl10)],
            dashStyle:'dot'
            
        }]
    });

    

  }


  updateGraphVal(elem, param, val){
    //@ts-ignore
    this.data[elem][param] = val;
    //@ts-ignore
    console.log(this.data[elem]);
    
  }

  saveGraphData(){
    //save data to db
    //@ts-ignore

    $('.btn-save-gd').text('Saving');
    $('.btn-save-gd').attr('disabled', 'true');
    this.data.title = this.formTitle;
    
    this.dataService.postSaveAudioEval(this.data).subscribe( (res:any) => {
      $('.btn-save-gd').text('Save');
      $('.btn-save-gd').removeAttr('disabled');
      if(res.status == 0) {
          //@ts-ignore
          $('#modal-gleft').modal('hide');
          $('#modal-gright').modal('hide');
          alert(res.msg);
          this.loadData();
        } else {
          alert(res.msg);
        }
    } );
    
    
  }

  updateMasked(label, value){
    this.data.masked[label] = value;
  }

  updateMaskedRes(label, e){
    console.log('res', e.target.checked);
  }
  
  setFormTitle(title){
    this.formTitle = title;
  }

  

}
