import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  constructor(private router:Router, private dataService: DataService) { 
  	this.router.routeReuseStrategy.shouldReuseRoute = function() {
          return false;
      };
  }

  data=[];
  limit = 20;
  search="";

  ngOnInit() {
  	this.loadNotifications();
  }

  loadNotifications(){
  	this.dataService.getNotifications(this.limit, this.search).subscribe( (data: any[] )=> {
	  		this.data = data;
	  		console.log(this.data);

	  	});
  }

  searchNotifs(search) {
  	this.search = search;
  	this.loadNotifications();
  }

  loadMore() {
  	this.limit +=20;
  	this.loadNotifications();
  }

  deleteNotification(id) {
      var data = {"id":id};
      var cnf = confirm("Are you sure you want to delete this notification?");
      if(cnf) {
        this.dataService.postDeleteNotification(data).subscribe( (res:any) => {
          if(res.status == 0) {
            
            this.loadNotifications();
          } else {
            alert(res.msg);
          }
        } );
      }
    }


}
