import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
	
	data:any = {events:[]};
	limit = 20;
	mode = 1;

  	constructor(private dataService: DataService, private router:Router) { }

	ngOnInit() {
		this.loadEvents();
	}

	loadEvents(){
		this.dataService.getEvents(this.limit, this.mode).subscribe( (data: any[] )=> {
	  		this.data = data;
	  	});
	}

	loadMore() {
		this.limit +=20;
	    this.loadEvents();
	}

	setMode(mode) {
		this.mode = mode;
		this.loadEvents();
	}

	deleteEvent(id) {
	    var cnf = confirm("Are you sure you want to delete this event?");

	    if(cnf) {
	      var data = {"event_id":id};
	      this.dataService.postDeleteEvent(data).subscribe( (res:any) => {
	        if(res.status == 0) {
	            alert(res.msg);
	            this.loadEvents();
	            
	            
	          } else {
	            alert(res.msg);
	          }
	      } );
	    }

	  }

}
