import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss']
})
export class RecentActivitiesComponent implements OnInit {

  	constructor(private dataService: DataService) { }
  	data = [];
	limit =20;
	date = "";
  date2 = "";
  ngOnInit() {
  	this.loadUpdates();

  	$( function() {
		(<any>$("#schedule-date" )).datepicker({
	    	dateFormat: "dd-mm-yy",
	    	onSelect: function (dateText, inst) {
				this.focus();

		    }
		});

		
	});
  }

  loadUpdates(){
  	this.dataService.getRecentUpdates(this.limit, this.date, this.date2).subscribe( (data: any[] )=> {
	  		this.data = data;
	  		
	  	});
  }

  loadMore() {
	this.limit +=20;
	this.loadUpdates();
  }

  switchDate(date){
  	this.date = date;
  	this.loadUpdates();
  }

  setDate(date){
    this.date = date;
    this.loadUpdates();
  }

  setDate2(date){
    this.date2 = date;
    this.loadUpdates();
  }

  clearDateFilter(){
    this.date = "";
    this.date2 = "";
    $('.date-picker').val('');
    this.loadUpdates();
  }



}
