import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {
              status:0,
              appointments:0,
              patients:0,
              discharges:0,
              period_discharges:0,
              total_patients:0,
              today:"",
            };

  billing:any = {
    status:0,
    bill_total:0,
    bill_settled:0,
    bill_unsettled:0,
    bill_modes:[],
    payment_modes:[]

  }
  period = 1;
  date1 ="";
  date2 ="";
  roleId:any = 0;
  departmentId:any = 0;
  selectedConsultant = 0;
  consultantName = "";
  selectedDept = 0;
  deptName = "";
  loading = 0;
  consultants:any = [];
  depts:any = [];

  ageData = {
    labels: ['1-10 Years of age', '10-20 Years of age', '20-40 Years of age', '40-60 Years of age','60+ Years of age'],
    datasets: [{
        
        backgroundColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A', '#43A2C9'],
        borderColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A', '#43A2C9'],
        data:[3200, 4300, 1760, 520, 2720],
    }],

  };

  

  ngOnInit() {

    this.roleId = localStorage.getItem("role_id");
    this.departmentId = localStorage.getItem("department");
    console.log("dept", this.departmentId);

    this.loadData();
    
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    /*let context = this;
    $('body').on('focus', '#therapistAc', function(){
      let moi = $(this);
      //@ts-ignore
      moi.autocomplete({
				source: function(request, response){
					context.dataService.getTherapistsAc(request.term).subscribe( (data: any[] ) =>{
						var array = data.map(function(element){
							return {value: element['consultant_name'] , name: element['consultant_name'], id: element['id']};
						  });
						response(array);
					});
				},
        delay:500,
				select: function(event, ui) {
					context.selectedConsultant = ui.item.id;
          moi.val(ui.item.name);
          context.loadData();
					return false;
				}
			});

    })

    $('body').on('focus', '#departmentAc', function(){
      let moi = $(this);
      //@ts-ignore
      moi.autocomplete({
				source: function(request, response){
					context.dataService.getDeptAc(request.term).subscribe( (data: any[] ) =>{
						var array = data.map(function(element){
							return {value: element['department_name'] , name: element['department_name'], id: element['id']};
						  });
						response(array);
					});
				},
        delay:500,
				select: function(event, ui) {
					context.selectedDept = ui.item.id;
          moi.val(ui.item.name);
          //context.loading = 1;
          context.loadData();
					return false;
				}
			});

    })*/
  }

  loadData(){
    this.loading = 1;
    console.log("loading data", this.loading);
    //console.log("dept", this.departmentId == '20');
    if(this.roleId == '1' || this.roleId == '11' || this.departmentId == '20' ){
      this.dataService.getAnalyticsAdmin(this.period, this.date1, this.date2, this.selectedConsultant, this.selectedDept).subscribe( (data: any[] )=> {
        this.data = data;
        this.loading = 0;
        
      });

      this.dataService.getAnalyticsAdminBilling(this.period, this.date1, this.date2, this.selectedConsultant, this.selectedDept).subscribe( (data: any[] )=> {
        this.billing = data;
        this.loading = 0;
        
      });



    } else {
      this.dataService.getAnalytics(this.period, this.date1, this.date2).subscribe( (data: any[] )=> {
        this.data = data;
        this.loading = 0;
        
      });
    }

    
  }

  changePeriod(period) {
    this.period  = period;
    if(period != 4){
      this.loadData();
    }
    
  }

  customDateFilter(){
    
    if(this.date1.length < 4 || this.date2.length < 4  ) {
      alert("Need to select both start date and end date");
    } else {
      this.loadData();
    }

    

  }

  plotGraph(data, element){
    console.log("plotting ", element);
    
    
    //@ts-ignore
    $('#age-graph').highcharts({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Browser market shares in January, 2018'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
            name: 'Chrome',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'Internet Explorer',
            y: 11.84
        }, {
            name: 'Firefox',
            y: 10.85
        }, {
            name: 'Edge',
            y: 4.67
        }, {
            name: 'Safari',
            y: 4.18
        }, {
            name: 'Sogou Explorer',
            y: 1.64
        }, {
            name: 'Opera',
            y: 1.6
        }, {
            name: 'QQ',
            y: 1.2
        }, {
            name: 'Other',
            y: 2.61
        }]
    }]
    });
    

  }

  clearAdminFilters(){
    this.selectedConsultant = 0;
    this.consultantName = "";
    this.selectedDept = 0;
    this.deptName = "";
    this.loadData();
    $("#therapistAc").val("");
  }

  getConsultant(term){
    console.log("term", term);
    this.dataService.getTherapistsAc(term).subscribe( (data: any[] ) =>{
     this.consultants = data; 
     
    });
  }

  setConsultant(term){
    let filtered = this.consultants.filter((consultant)=>consultant.consultant_name.toLowerCase().includes(term.toLowerCase()));
    this.selectedConsultant = filtered[0].id;
    this.loadData();
    
  }

  getDept(term){
    console.log("term", term);
    this.dataService.getDeptAc(term).subscribe( (data: any[] ) =>{
     this.depts = data; 
     
    });
  }

  setDept(term){
    let filtered = this.depts.filter((dept)=>dept.department_name.toLowerCase().includes(term.toLowerCase()));
    this.selectedDept = filtered[0].id;
    this.loadData();
    
  }

}
