import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-unified-form',
  templateUrl: './unified-form.component.html',
  styleUrls: ['./unified-form.component.scss']
})
export class UnifiedFormComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute,) { }

  forms = [];
  selectedForm = {};
  id = '0';
  patient = {};
  responseTypes = {};


  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);
    this.loadPatient();
  	this.loadForms(0);
    this.loadResponseTypes();

     $('body').on('click', '.btn-del-option', function(){
       //@ts-ignore
       $(this).parent().parent().parent().remove();
     });

  }

  loadPatient() {
    this.dataService.getPatient(this.id).subscribe( (data: any[] )=> {
        this.patient = data;
        console.log(this.patient);
    });
  }

  loadForms(form_id) {
  	this.dataService.getUnifiedFormsBuilder(0, null, 50).subscribe( (data: any[] )=> {
	  		this.forms = data;
	  		//@ts-ignore
	  		if (data.forms.length > 0) {
	  			//@ts-ignore
          if (form_id == 0) {
            //@ts-ignore
            this.loadForm(data.forms[0].id);
          } else {
            this.loadForm(form_id);
          }
	  			
	  		}

	  } );
  }

  loadForm(form_id) {
  	this.dataService.getUnifiedForm(form_id, this.id).subscribe( (data: any[] )=> {
	  		this.selectedForm = data;
	  		console.log(this.selectedForm);

	  });
  }


  selectOption(question, option) {
  	//@ts-ignore
	if (this.selectedForm.data[question].options[option].state == 1) {
		//@ts-ignore
  		this.selectedForm.data[question].options[option].state = 0;
  	} else {
  		//@ts-ignore
  		var context = this;
  		//@ts-ignore
  		this.selectedForm.data[question].options.forEach(function(i, k) {
  			i.state = 0;
  		})
  		//@ts-ignore
  		this.selectedForm.data[question].options[option].state = 1;
  	}

  	
  }

  saveForm() {
    $('.btn-submit').attr('disabled', 'true');
    var data = {"patient_id":this.id, "form":this.selectedForm};
    this.dataService.postSaveUnifiedForm(data).subscribe( (res:any) => {
      $('.btn-submit').removeAttr('disabled');
      console.log(res);
      if(res.status == 0) {
        alert(res.msg);
        //@ts-ignore
        this.loadForm(this.selectedForm.id);
      } else {
        alert(res.msg);
      }


    });
    

  }

  updateResponse(question, option, value) {
    if (value.length > 0) {
      //@ts-ignore
      this.selectedForm.data[question].options[option].state = 1;
    } else {
      //@ts-ignore
      this.selectedForm.data[question].options[option].state = 0;
    }
    //@ts-ignore
    this.selectedForm.data[question].options[option].response = value;

  }


  //Manage forms
  submitNew(data) {
    $('#btn-submit-new').attr('disabled', 'true');
    this.dataService.postNewUnifiedForm(data).subscribe( (res:any) => {
      $('#btn-submit-new').removeAttr('disabled');
      console.log(res);
      if(res.status == 0) {
        this.loadForms(res.form_id);
        $('#modal-add-form').modal('hide');
        //@ts-ignore
        
      } else {
        alert(res.msg);
      }


    });

  }

  loadResponseTypes(){
     this.dataService.getResponseTypes().subscribe( (data: any[] )=> {
        this.responseTypes = data;
        console.log(data);

    }); 
  }

  appendOption(){

     var optString = "";

     //@ts-ignore
     var rt = this.responseTypes.data;

     $.each(rt, function(i,k){
       optString += `<option value="`+this.id+`">`+this.type+`</option>`;
     });

     var option = `
       <div class="form-group">
          <p>
            <label class="label-small">Response type</label>
            <select class="response-type">
              `+optString+`
            </select>
            <span> <img src="assets/img/close.png" style="cursor: pointer;width: 15px;margin-left: 32%;" class="btn-del-option"> </span>
          </p>
          <p>
             <label class="label-small">Option Text or Placeholder</label>
             <input type="text" class="form-control response-text">
          </p>
          <hr/>
        </div>  
     ` ;

     $('.response-wrap').append(option);
  }

  submitQuestion(){

    var question = $('#question-title').val();

    var responses = [];
    $('.response-wrap .form-group').each(function(i,k){
      var response = {
        "type": $(this).find('.response-type').val(),
        "text": $(this).find('.response-text').val(),
      };

      responses.push(response);

    });

    var data = {
      //@ts-ignore
      "form_id" : this.selectedForm.id,
      "question": question,
      "responses": responses
    };

    
    $('#btn-submit-question').attr('disabled', 'true');
    this.dataService.postUfQuestion(data).subscribe( (res:any) => {
      $('#btn-submit-question').removeAttr('disabled');
      
      if(res.status == 0) {
        //@ts-ignore
        this.loadForms(this.selectedForm.id);
        $('#modal-add-question').modal('hide');
        //@ts-ignore
        document.getElementById("formQ").reset(); 
        
      } else {
        alert(res.msg);
      }


    });
    

  }

  deleteQuestion(id) {
    var cnf = confirm("Are you sure you want to delete this question?");
    var data = {"id":id};
    if (cnf) {
      this.dataService.postDeleteUfQuestion(data).subscribe( (res:any) => {
        if(res.status == 0) {
          //@ts-ignore
          this.loadForms(this.selectedForm.id);
          
        } else {
          alert(res.msg);
        }


      });
    } 

  }

  submitHeading(data){
    //@ts-ignore
    data.form_id = this.selectedForm.id;
    
    
    $('#btn-submit-heading').attr('disabled', 'true');
    this.dataService.postUfHeading(data).subscribe( (res:any) => {
      $('#btn-submit-heading').removeAttr('disabled');
      
      if(res.status == 0) {
        //@ts-ignore
        this.loadForms(this.selectedForm.id);
        $('#modal-add-heading').modal('hide');
        //@ts-ignore
        document.getElementById("formHeading").reset(); 
        
      } else {
        alert(res.msg);
      }


    });

    
    

  }



}
