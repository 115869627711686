import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private formBuilder: FormBuilder) { }

  goals:any = {goals:[]};
  levels = [];
  categories = [];
  selectedLevel = {"id":0, "level":""};
  selectedCategory = {"id":0, "level":""};
  selectedGoal = {"id":0};
  subGoals = [];
  steps = [];
  selectedStep = {};
  tutorials = [];
  video = {};
  noVideoText = "Loading video";
  requirements = [];
  stats = [];
  selectedActivity = {};

  //upload
  file: File = null;
  uploadForm: FormGroup;
  error:string;
  fileUpload = {status: '', message: '', filePath: ''};
  upFiles = [];
  fileTitle = '';

  ngOnInit() {

  	this.loadCategories();
  	this.loadLevels();
  	this.loadGoals();
    this.loadStats();

    this.uploadForm = this.formBuilder.group({
      file: [''],
      file_title: "",
    });

    //stop video playback on modal close
    $('#modal-player').on('hidden.bs.modal', function (e) {
      //@ts-ignore
      $('#modal-player video').attr("src", '');
      //@ts-ignore
      this.video = {};
      //@ts-ignore
      this.noVideoText = "Loading video";

    });

  	

  }

  loadGoals() {
  	this.dataService.getGoals(this.selectedLevel.id, this.selectedCategory.id).subscribe( (data: any[] )=> {
	  		this.goals = data;
	  		
	  } );
  }

  loadCategories() {
  	this.dataService.getGoalCategories().subscribe( (data: any[] )=> {
	  		this.categories = data;
	  		//@ts-ignore
	  		if (data.categories.length > 0) {
	  			//@ts-ignore
	  			this.selectedCategory = data.categories[0];

	  		}
	  		
	} );
  }

  loadLevels() {
  	this.dataService.getGoalLevels().subscribe( (data: any[] )=> {
	  		this.levels = data;
	  		//@ts-ignore
	  		if (data.levels.length > 0) {
	  			//@ts-ignore
	  			this.selectedLevel = data.levels[0];
	  			
	  		}
	  		
	} );
  }


  selectGoal(goal) {

  	this.selectedGoal = goal;
  	this.subGoals = [];
    //load subGoals
  	this.dataService.getSubGoals(goal.id).subscribe( (data: any[] )=> {
	  		this.subGoals = data;
	  		
	  } );

    //Load Steps
    this.loadSteps(goal.id);

  	//load requirements
    this.loadRequirements(goal.id);
    

    //load tutorials

    this.loadTutorials(goal.id);

  }


  selectLevel(index) {
  	//@ts-ignore
  	this.selectedLevel = this.levels.levels[index];
  	this.loadGoals();
  }

  selectCategory(index) {
  	//@ts-ignore
  	this.selectedCategory = this.categories.categories[index];
  	this.loadGoals();
  }

  submitGoal(data) {
    data.level_id = this.selectedLevel.id;
    data.category_id = this.selectedCategory.id;
    $('#btn-add-goal').attr('disabled', 'true');

    this.dataService.postAddGoalLib(data).subscribe( (res:any) => {
      $('#btn-add-goal').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-goal').modal('hide');
            this.loadGoals();
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  deleteGoal(goal) {

    var cnf = confirm("Are you sure you want to delete this goal?");
    var data = {"goal_id":goal.id  };

    this.dataService.postDeleteGoalLib(data).subscribe( (res:any) => {
      if(res.status == 0) {
            this.loadGoals();
            
          } else {
            alert(res.msg);
          }
    });

  }

  editGoal(data) {
    $('#btn-edit-goal').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;
    this.dataService.postEditGoalLib(data).subscribe( (res:any) => {
      $('#btn-edit-goal').removeAttr('disabled');
      if(res.status == 0) {
            this.loadGoals();
            $('#modal-edit-goal').modal('hide');
          } else {
            alert(res.msg);
          }
    });

  }

  addLevel() {
    var cnf = confirm("Do you really want to add a new level?");

    if (cnf) {
      this.dataService.postAddGoalLevel().subscribe( (res:any) => {
        
        if(res.status == 0) {
              alert(res.msg);
              this.loadLevels();
            } else {
              alert(res.msg);
            }
      });
    }

  }

  submitCategory(data) {
    
    $('#btn-add-category').attr('disabled', 'true');

    this.dataService.postAddGoalCategory(data).subscribe( (res:any) => {
      $('#btn-add-category').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-category').modal('hide');
            this.loadCategories();
            alert(res.msg);
            
          } else {
            alert(res.msg);
          }
    });

  }

  submitSub(data) {
    $('#btn-add-subgoal').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;

    this.dataService.postAddActivity(data).subscribe( (res:any) => {
      $('#btn-add-subgoal').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-subgoal').modal('hide');
            //@ts-ignore
            this.dataService.getSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                this.subGoals = data;
                
            } );
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  loadSteps(goal_id) {
    this.dataService.getSteps(goal_id).subscribe( (data: any[] )=> {
        this.steps = data;      
        
        
    } );
  }

  loadRequirements(goal_id) {
    this.dataService.getRequirements(goal_id).subscribe( (data: any[] )=> {
        this.requirements = data;        
        //console.log(this.requirements);
    } );
  }

  submitStep(data) {
    $('#btn-add-step').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;

    this.dataService.postAddStep(data).subscribe( (res:any) => {
      $('#btn-add-step').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-step').modal('hide');
            
            this.loadSteps(this.selectedGoal.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  deleteStep(step_id) {

    var cnf = confirm("Are you sure you want to delete this step?");
    var data = {"id":step_id};

    this.dataService.postDeleteStep(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
            this.loadSteps(this.selectedGoal.id);
            
          } else {
            alert(res.msg);
          }
    });

  }

  selectStep(step){
    this.selectedStep = step;
    
  }

  submitReq(data) {
    $('#btn-add-req').attr('disabled', 'true');
    //@ts-ignore
    data.goal_id = this.selectedGoal.id;



    this.dataService.postAddReq(data).subscribe( (res:any) => {
      $('#btn-add-req').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-req').modal('hide');
            
            this.loadRequirements(this.selectedGoal.id);
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  deleteReq(req_id) {
    var cnf = confirm("Are you sure you want to delete this item?");
    var data = {"id":req_id};

    this.dataService.postDeleteReq(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
            this.loadRequirements(this.selectedGoal.id);
            
          } else {
            alert(res.msg);
          }
    });
  }


  loadTutorials(goal_id) {
    this.dataService.getTutorials(goal_id).subscribe( (data: any[] )=> {
      this.tutorials = data;
      
      
    });
  }

  loadStats() {
    this.dataService.getGoalStats().subscribe( (data: any[] )=> {
      this.stats = data;
      
      
    });
  }

  loadVideo(id){

    //@ts-ignore
    $('#modal-player').modal();

    this.dataService.getVideo(id).subscribe( (data: any[] ) =>{
        this.video = data;

        //@ts-ignore
        if (data.status == 1) {
          //@ts-ignore
          this.noVideoText = data.msg;
        }

    });
  }

  submitUpload() {
    
    const formData = new FormData();

    if(this.upFiles.length){
       for(let i=0 ; i < this.upFiles.length ; i++)
         formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
     }

    formData.append('file_title', this.fileTitle);
    //@ts-ignore
    formData.append('goal_id', this.selectedGoal.id);



    

    
    //@ts-ignore
    $('#btn-upload').text('Uploading');
    //@ts-ignore
    $('#btn-upload').attr('disabled', 'true');

    this.dataService.postUpload(formData).subscribe( (res:any) => {
      //@ts-ignore
        $('#btn-upload').text('Upload');
        //@ts-ignore
        $('#btn-upload').removeAttr('disabled');        
      
      if(res.status == 0) {
        
         alert(res.msg);
         //@ts-ignore
         $('#modal-upload').modal('hide');

         this.selectGoal(this.selectedGoal);
        
      } else {
        alert(res.msg);
      }

      

     });

   
  }

  onFileTitle(data) {
    this.fileTitle = data;
    
  }

  onFileSelect(event) {

    
    if(event.target.files.length > 0) {
      
      this.uploadForm.get('file').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }
      
    }
    

  }


  addNewTask(activity){
    this.selectedActivity = activity;
    $('#modal-add-task').modal();
  }

  submitTask(data) {
    //@ts-ignore
    data.activity_id = this.selectedActivity.activity_id;

    $('#btn-add-task').attr('disabled', 'true');

    this.dataService.postAddGoalTask(data).subscribe( (res:any) => {
      $('#btn-add-task').removeAttr('disabled');
        if(res.status == 0) {
            $('#modal-add-task').modal('hide');
            this.dataService.getSubGoals(this.selectedGoal.id).subscribe( (data: any[] )=> {
                this.subGoals = data;
                
            } );
            
            
          } else {
            alert(res.msg);
          }
    });

  }



}
