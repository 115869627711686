import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute,  private dataService: DataService) { }

  id = 0;
  data:any = {status:0, upcoming:[]};
  other_appointments = [];
	other_months = [];
	appointmentsMode = 1;
  selectedMonth = "";
  rescheduleId:any;
  reschedule=[];
  therapists=[];
	selectedTherapist=0;
	rescheduleDate= "";
	freeSlots = [];

  ngOnInit() {
    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();

  }

  loadData(){

    this.dataService.getPatientAppointments(this.id).subscribe( (data: any[] )=> {

      this.data = data;
      //@ts-ignore
      this.other_appointments = data.upcoming;
				//@ts-ignore
				this.other_months = data.upcoming_months;
				//@ts-ignore
				this.selectedMonth = data.upcoming_months[0];
				this.appointmentsMode = 1;
        
      
    } );

  }

  selectMonth(month){
    this.selectedMonth = month;
    $('#collapseExample').collapse('show');
  }
  
  switchAppointmentsMode(mode){
      this.appointmentsMode = mode;
      if (mode==1) {
        this.other_appointments = this.data.upcoming;
        this.other_months = this.data.upcoming_months;
        
      }else if(mode==2){
        this.other_appointments = this.data.past;
        this.other_months = this.data.past_months;

      }
  }

  markPresent(id) {
    	
    var data = {"appointment_id":id};
    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadData();
            
           
          } else {
            alert(res.msg);
          }
      } );

    

  }

  markAbsent(id){
    
      var data  = {"id":id};
      this.dataService.postMarkAbsent(data).subscribe( (res: any) => {
        if(res.status == 0){
          this.loadData();
        } else {
          alert(res.msg);
        }

      });
  }

  loadReschedule(appointment_id) {
    
    $('#rescheduleModal').modal('show');

    this.rescheduleId = appointment_id;
    this.dataService.loadReschedule(appointment_id).subscribe( (data: any[] )=> {
      this.reschedule = data;
      
    } );
  }

  submitTherapyBill(data){
    this.dataService.localData.billContents = [{
      description:data.therapy,
      rate:"",
      qty:data.session_count,
      amount:"",
      is_therapy:1
    }];

    $("#billingModal").modal('hide');

    this.router.navigate(['/dashboard/generate-bill/'+this.id]);

  }


}
