import { Component, OnInit, NgZone } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-generate-bill',
  templateUrl: './generate-bill.component.html',
  styleUrls: ['./generate-bill.component.scss']
})
export class GenerateBillComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private ngZone: NgZone) { }

  patient:any = {patient_id:0, patient_name:"", phone:""};
  id:any = 0;
  billContents:any = [
    {description:"", rate:"", qty:1, amount:"", dates:[""], is_therapy:0, dept_id:0, edit_mode:true},
  ];
  totalAmt:any = 0;
  paidAmt:any = 0;
  payableAmt:any = 0;
  walletBalance:any = 0;
  therapists:any = {therapists:[]};
  selectedTherapist:any = 0;
  consultantName:"";
  billHeads:any = [];
  others:any = [];
  discount:any = 0;
  discountSlabs:any = [0,25,50,100];  
  discountAmt:any = 0;
  paymentModes:any = [
                      "",
                      "Cash",
                      "Card",
                      "Cheque",
                      "UPI"
                    ];
  selectedPaymentMode:any = 1;
  billDate:any = "";
  billHeadType:any = 1;
  billHeadTitle:any = "";
  payerName:any = "";

  ngOnInit() {

    this.id = (this.route.snapshot.paramMap.get('id') != null) ? this.route.snapshot.paramMap.get('id') : 0;
    this.loadPatient();
    this.loadTherapists();
    this.loadBillHeads();
    this.loadPaymentModes();

    

    let context = this;
    //@ts-ignore
    if(this.dataService.localData.roleId == 3 || this.dataService.localData.roleId == 4 || this.dataService.localData.roleId == 12){
      this.selectedTherapist = this.dataService.localData.userId;
      
    }

    if(this.dataService.localData.billContents.length > 0){
      this.billContents = this.dataService.localData.billContents;
    }

    //Autocomplete
    
    $('body').on('focus', '#inputPatient', function(){
      let moi = $(this);
      
      //@ts-ignore
      moi.autocomplete({
        //appendTo: '#addAppointment',
        source: function(request, response) {
            $.ajax({
              url:context.dataService.REST_API_SERVER + 'patient/autocomplete',
              type:'GET',
              dataType:'json',
              data: {term: request.term},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {value: element['op_number'] + " - " + element['patient_name'] , op_number: element['op_number'], patient_id: element['patient_id'], patient_name: element['patient_name'], phone: element['phone']};
                });
                response(array);
    
              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.patient_name);
              context.ngZone.run(() => {
                context.router.navigate(['/dashboard/generate-bill/'+ui.item.patient_id]);
              });

              
              
              return false;
          }
      });
    });

    

  }

  loadPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data: any = {status:0, wallet_balance:0} )=> {
      if(data.status == 0){
        this.patient = data;
        this.walletBalance = data.wallet_balance;
        //this.paidAmt = parseInt(this.paidAmt) + parseInt(this.walletBalance) ;
      }
      
    });
  }

  loadTherapists(){
    this.dataService.getTherapists().subscribe( (data: any = {status:0, today:""} )=> {
      if(data.status == 0){
        this.therapists = data;
        this.billDate = data.today;
        //@ts-ignore
        if(this.dataService.localData.roleId == 3 || this.dataService.localData.roleId == 4 || this.dataService.localData.roleId == 12){
          var selected = this.therapists.therapists.find( (e) =>  e.id == this.dataService.localData.userId );
          this.consultantName = selected.name;
          
        }

      }
      
      
    });
  }

  loadPaymentModes(){
    this.dataService.getPaymentModes().subscribe( (data: any = {status:0} )=> {
      this.paymentModes = data;
    });
  }

  addBillRow(){
    this.billContents.forEach(element => {
      element.edit_mode = false;
    });
    this.billContents.push({description:"", rate:"", qty:1, amount:"", dates:[""], is_therapy:0, dept_id:0, edit_mode:true});
  }

  removeBillRow(index){
    this.billContents.splice(index, 1);
    var total = 0;
    this.billContents.forEach(element => {
      total += element.amount;
    });
  
    this.totalAmt = total;
    this.calculatePayable();
  }

  generateBill(){
    $('#btn-submit').attr('disabled', 'true');
    $('#btn-submit').text('Submitting');

    let contents = [];

    this.billContents.forEach(elem => {
      if(elem.description.length > 0  && !isNaN(elem.rate) && !isNaN(parseInt(elem.qty))  ){
        contents.push(elem);
      }
    });

    var data = {
      patient_id: this.id,
      billcontents:contents,
      therapist:this.selectedTherapist,
      paid:this.paidAmt,
      discount:this.discountAmt,
      payment_mode:this.selectedPaymentMode,
      bill_date:this.billDate,
      payer:this.payerName,
    };

    console.log("contents", contents);

    
    if(contents.length > 0){
      this.dataService.postBill(data).subscribe( (res:any) => {
        $('#btn-submit').removeAttr('disabled');
        $('#btn-submit').text('generate Bll');

        if(res.status == 0) {
          //alert(res.msg);
          this.dataService.localData.billContents = [];
          this.router.navigate(['/dashboard/bill/'+res.bill_id]);
          
          
        } else {
          alert(res.msg);
        }

      }); 
    } else {
      alert("Enter at least one item, and make sure you have entered the fields properly");
    } 

  }
  
  calculateAmount(index){
    var rate = this.billContents[index].rate;
    var qty = this.billContents[index].qty;
    var amt = 0;
    var total = 0;

    if(!isNaN(rate) && !isNaN(qty) ){
      amt = rate * qty;
      this.billContents[index].amount = amt;

      this.billContents.forEach(element => {
        total += element.amount;
      });
    
      this.totalAmt = total;
      //this.paidAmt = total;

    } else {
      amt = 0;
    }

    this.calculatePayable();
    

  }
  

  toggleTherapy(index){
    if(this.billContents[index].is_therapy == 1 ) {
      this.billContents[index].is_therapy = 0;
    } else {
      this.billContents[index].is_therapy = 1;
    }
  }

  loadBillHeads(){
    this.dataService.getBillHeads(1, this.billHeadType).subscribe( (data: any = {status:0} )=> {
      this.billHeads = data;      
    });
  }

  setBillHead(i, j){
    
    if(this.billHeads[j].id == 'others'){
      this.others.push(i);
      this.billContents[i].description = "";
      this.billHeadTitle = "";
      $("input[name=description"+i+"]").focus();
    } else if(this.billHeads[j].id == 'categories'){
      this.billHeadType = this.billHeads[j].category_id;
      this.billHeadTitle = this.billHeads[j].title;
      this.loadBillHeads();
      
    } else {
      this.billContents[i].description = this.billHeads[j].title;
      this.billContents[i].rate = this.billHeads[j].amount;
      this.billContents[i].is_therapy = this.billHeads[j].is_therapy;
      this.billContents[i].dept_id = this.billHeads[j].dept_id;
      this.calculateAmount(i);
    }

  }

  removeFreeText(i){
    if(this.others.indexOf(i) >= 0 ){
      this.others.splice(this.others.indexOf(i), 1);
    }
  }

  setDiscount(val){
    this.discount = val;
    this.calculatePayable();
  }

  calculatePayable(){
    if(this.discount > 0){
      this.discountAmt = (this.discount/100)*this.totalAmt;
      this.payableAmt = this.totalAmt - this.discountAmt;
    } else {
      this.payableAmt = this.totalAmt;
    }

    this.setPaidAmt();
  }

  setPaidAmt(){
    if(this.payableAmt > this.walletBalance){
      this.paidAmt = this.payableAmt - this.walletBalance;
    } else {
      this.paidAmt = 0;
    }
  }

  changeConsultant(val){
    
    var selected = this.therapists.therapists.find( (e) =>  e.id == val );
    var type;
    console.log(selected.role_id);
    if(selected.role_id == 12){
      type = 2;
    } else {
      type = 1;
    }

    if(type != this.billHeadType){
      this.billHeadType = type;
      this.loadBillHeads();
    }

  }

  addBillDate(i){
    this.billContents[i].dates.push("");
    //this.billContents[i].qty = this.billContents[i].dates.length;
    
  }

  updateBillDate(i,j, val){
    this.billContents[i].dates[j] = val;
    if(val == ""){
      this.billContents[i].dates.splice(j, 1);
    }

    this.billContents[i].qty = this.billContents[i].dates.length;
    this.calculateAmount(i);
  }

}
