import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-lest0',
  templateUrl: './lest0.component.html',
  styleUrls: ['./lest0.component.scss']
})
export class Lest0Component implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id = 0;
  type = 'lest0';
  data = [];
  comments = [];
  comment = "";
  selectedComment = {id:0, comment:''};
  today = "";
  color = "Red";
  dataDates = [];
  selectedDates = [];

  ngOnInit() {

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

  	let context = this;
  	document.getElementById("lest0").addEventListener('mousedown', function(e) {
      context.clickedCanvas(e);
    })

    this.loadLest();
    this.loadComments();

  }

  postComment(data){

  	$('#btn-comment').attr('disabled','true');

  	data.patient_id = this.id;
    data.type = 2;
  	let push = {id: 0, comment:data.comment, user: '', time: '', type:2};
  	this.comments.push(push);
  	this.dataService.postDenverComments(data).subscribe( (res:any) => {
  		$('#btn-comment').removeAttr('disabled');
        if (res.status == 0) {
        	this.comment = "";
        	this.loadComments();
        } else {
        	alert(res.msg);
        }

    });

  }

  loadComments(){
  	this.dataService.getDenverComments(this.id, 2).subscribe( (data: any[] )=> {
        this.comments = data;
        console.log('comments', data);
    } );
  }

  clickedCanvas(e){
    //@ts-ignore
    var rect = lest0.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    var found = 0;

    //check if the click was around any symbols
    this.data.every(function(k, i){
      if(x > k.x-5 && x < k.x+20 && y > k.y-5 && y < k.y+20){
        found = k.id;
        
      }
      
      if(found != 0){
        return false;
        
      } else {
        return true;
      }
      
    });
    if(found != 0){
      //delete mark
      var cnf = confirm("Do you want to delete this mark?");
      if(cnf) {
        let data= {patient_id: this.id, found: found, type:"lest0"};
        this.dataService.postDeleteCanvasCoords(data).subscribe( (res:any) => {
          if(res.status == 0){
            this.loadLest();
          } else {
            alert(res.msg);
          }
          
        });
      }
    } else {
      this.showModal(e);
    }

  }

  showModal(event){
  	$('#x_val').val(event.clientX);
    $('#y_val').val(event.clientY);
    
    

    $('#modal-options').modal();
  }

  insertOption(option){
  	
  	var x_val = $('#x_val').val();
    var y_val = $('#y_val').val();
    

    //@ts-ignore
    var ctx = document.getElementById("lest0").getContext('2d');
    //@ts-ignore
    var rect = lest0.getBoundingClientRect();
  	//@ts-ignore  
    var x = x_val - rect.left - 10;
    //@ts-ignore
    var y = y_val - rect.top-10;

    
    this.drawOption(x, y, option);
    $('#modal-options').modal('hide');

    //save to db

    let data = {
    	patient_id:this.id, 
    	x:x, 
    	y:y,
    	option:option,
    	type: this.type
    };

    this.data.push({
      x:x,
      y:y,
      option:option
    });

    this.dataService.postLestData(data).subscribe( (res:any) => {
      this.loadLest();
    });

  }

  drawOption(x, y, option){
  	let image = new Image();
    image.src = (option == 0) ?  'assets/img/icon-close-red.svg' : 'assets/img/icon-check.svg'  ;
    //@ts-ignore
    var ctx = document.getElementById("lest0").getContext('2d');
    image.onload = function(){
    	ctx.drawImage(image, x,y, 20,20);	
    }
  }

  insertOptionText(option, color){
    var x_val = $('#x_val').val();
    var y_val = $('#y_val').val();
    var text = "";
    if(option == 1){
      text = "✓";
    } else {
      text = "⨯";
    }

    //@ts-ignore
    var ctx = document.getElementById("lest0").getContext('2d');
    //@ts-ignore
    var rect = lest0.getBoundingClientRect();
  	//@ts-ignore  
    var x = x_val - rect.left; // - 10;
    //@ts-ignore
    var y = y_val - rect.top; //-10;

    ctx.fillStyle = color;
    ctx.font = "30px Arial";
    ctx.fillText(text, x, y);

    $('#modal-options').modal('hide');

   
    //save to db

    let data = {
    	patient_id:this.id, 
    	x:x, 
    	y:y,
    	option:option,
    	type: "lest0",
      date: this.today,
      color:this.color,
    };

    console.log(data.date);

    this.dataService.postLestData(data).subscribe( (res:any) => {
      this.loadLest();
    });

  }

  drawOptionText(x, y, option, color){
    console.log(x,y);
    if(color == null || color == ""){
      color = "black";
    }

    var text = "";
    if(option == 1){
      text = "✓";
    } else {
      text = "⨯";
    }

    //@ts-ignore
    var ctx = document.getElementById("lest0").getContext('2d');
    //@ts-ignore
    var rect = lest0.getBoundingClientRect();
  	

    ctx.fillStyle = color;
    ctx.font = "30px Arial";
    ctx.fillText(text, x, y);

    

    

  }


  loadLest(){
  	
  	//@ts-ignore
    var ctx = document.getElementById("lest0").getContext("2d");
    //@ts-ignore
    ctx.clearRect(0,0,lest0.width, lest0.height);

    //prepare datestring;
    var ds = "";
    this.selectedDates.forEach(function(val, i){
      ds += val + ",";
    });

    this.dataService.getLestData(this.id, this.type, ds).subscribe( (data: any[] )=> {
        //@ts-ignore
        this.data = data.data;
        //@ts-ignore
        this.dataDates = data.dates;
        let context = this;
        
        if (this.data.length > 0) {
        	this.data.forEach(function(val,i) {
        		context.drawOptionText(val.x, val.y, val.option,  val.color);
        	})
        }

      
    } );

  }

  undoPrevious(){
    let data = {patient_id:this.id, type:this.type};
    this.dataService.postDeleteLastLest(data).subscribe( (res:any) => {
      
        if (res.status == 0) {
          this.loadLest();
        } else {
          alert(res.msg);  
        }

        

    });
  }

  selectComment(comment){
    this.selectedComment = comment;
    $('#modal-edit-note').modal('show');
  }

  editComment(data){
    this.dataService.postEditDenverComments(data).subscribe( (res:any) => {
      if(res.status == 0){
        this.loadComments();
        $('#modal-edit-note').modal('hide');
      } else {
        alert(res.msg);
      }

    });
  }

  selectDate(d){
    if(this.selectedDates.indexOf(d) > -1){
      this.selectedDates.splice(this.selectedDates.indexOf(d));
    } else {
      this.selectedDates.push(d);
    }

    this.loadLest();

  }


}
