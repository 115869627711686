import { Component, OnInit } from '@angular/core';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../data.service';



@Component({
  selector: 'app-homeprogram',
  templateUrl: './homeprogram.component.html',
  styleUrls: ['./homeprogram.component.scss']
})
export class HomeprogramComponent implements OnInit {

	goals = [];
	id = 0;
	selectedGoal= [];
	activities = [];
	tasks = [];
	uploads = {status:1, data:[]};
	tutorials = {status:1, files:[]};
  levels = [];
  categories = [];
  selectedLevel = 0;
  selectedCategory = 0;
  libraryGoals = [];
  user = [];
  video = {};
  tutorialsOffset=0;
  uploadsOffset=0;
  

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit() {

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadUser();
    this.loadGoalLevels();
    this.loadGoalCategories();
    this.loadLibraryGoals();


  	//carousel
  	//@ts-ignore


  }

  loadUser(){
  	this.dataService.getPatientHt(this.id).subscribe( (data: any[] )=> {
      this.user = data;
      console.log('user', data);
      //@ts-ignore
      if (data.status == 0) {
      	//@ts-ignore
      	this.loadGoals(data.phone);
      }
      
    });
  }


  loadGoals(phone){
  	this.dataService.getHtPatientGoals(phone, 0).subscribe( (data: any[] )=> {
  		//@ts-ignore
      this.goals = data.goals;
      console.log('patient goals', data);
      
    });
  }

  selectGoal(goal){
  	this.selectedGoal = goal;
  	this.loadActivities(goal.goal_id);
    this.loadPatientUploads(goal.goal_id);
    this.loadGoalTutorials(goal.goal_id, this.tutorialsOffset);
  }

  loadActivities(goal_id) {
  	this.dataService.getHtPatientActivities(goal_id).subscribe( (data: any[] )=> {
  		
      	this.activities = data;
      
    });
  }

  

  loadGoalLevels(){
    this.dataService.getHtGoalLevels().subscribe( (data: any[] )=> {
      this.levels = data;

    });  
  }

  loadGoalCategories(){
    this.dataService.getHtGoalCategories().subscribe( (data: any[] )=> {
      this.categories = data;
      console.log('categories', data);
    });  
  }

  loadLibraryGoals(){
    this.dataService.getHtLibraryGoals(this.selectedLevel, this.selectedCategory).subscribe( (data: any[] )=> {
        this.libraryGoals = data;
        console.log('goals', data);
    });   
  }

  changeLevel(value) {
    this.selectedLevel = value;
    this.loadLibraryGoals();
  }

  changeCategory(value) {
    this.selectedCategory = value;
    this.loadLibraryGoals();
  }

  selectLibGoal(index) {
    //console.log('event', event.value);
    //@ts-ignore
    let status = this.libraryGoals.goals[index].select_status;
    
    if (status == 0) {
      //@ts-ignore
      this.libraryGoals.goals[index].select_status = 1;
    } else {
      //@ts-ignore
      this.libraryGoals.goals[index].select_status = 0;
    }
  }

  submitAssignGoals(data){
    let selectedGoals = [];
    //@ts-ignore    
    this.libraryGoals.goals.forEach(function(k, i){
      if (k.select_status == 1) {
        selectedGoals.push(k);
      }
    });
    $('#btn-assign-goals').attr('disabled', 'true');
    $('#btn-assign-goals').text('Assigning');
    data.selected_goals = selectedGoals;
    //@ts-ignore
    data.phone = this.user.phone;
    console.log(data);

    this.dataService.postHtAssignGoals(data).subscribe( (res:any) => {
      $('#btn-assign-goals').removeAttr('disabled');
      $('#btn-assign-goals').text('Assign');
      if (res.status == 0) {
        $('#modal-assign-goal').modal('hide');
        //@ts-ignore
        this.loadGoals(this.user.phone);
        //send push
        
        this.dataService.postHtSendPush(res.goal_ids).subscribe( (res:any) => {
          //do nothing
        });
        


      } else {
        alert(res.msg);
      }


    });
  }

  

  loadPatientUploads(goal_id) {
    //@ts-ignore
    this.dataService.getHtPatientUploads(goal_id, this.user.phone, this.uploadsOffset).subscribe( (data: any[] )=> {
        //@ts-ignore
        this.uploads = data;
        console.log('uploads', this.uploads);
    });   
  }

  loadGoalTutorials(goal_id, offset) {
    //@ts-ignore
    this.dataService.getHtGoalTutorials(goal_id, offset).subscribe( (data: any[] )=> {
        //@ts-ignore
        this.tutorials = data;
        console.log('tutorials', this.tutorials);
    });   
  }

  loadVideo(id){

    //@ts-ignore
    $('#modal-player').modal();

    this.dataService.getVideo(id).subscribe( (data: any[] ) =>{
        this.video = data;
        console.log('video', data);
        //@ts-ignore
        if (data.status == 1) {
          //@ts-ignore
          this.noVideoText = data.msg;
        }

    });
  }

  markGoal(goal) {
    var data = {goal_id:goal};
    this.dataService.postHtMarkGoal(data).subscribe( (res:any) => {
      if (res.status == 0) {
        //@ts-ignore
        this.loadGoals(this.user.phone);  

        this.dataService.postHtSendPushGoalComplete(res.goal_id).subscribe( (res:any) => {
          //do nothing
        });
      } else {
        alert(res.msg);
      }


    });
  }

  tutorialsSlideRight(){
    this.tutorialsOffset += 3;

    //@ts-ignore
    this.loadGoalTutorials(this.selectedGoal.goal_id, this.tutorialsOffset);

  }

  tutorialsSlideLeft(){
    this.tutorialsOffset -= 3;
    //@ts-ignore
    this.loadGoalTutorials(this.selectedGoal.goal_id, this.tutorialsOffset);
  }

  uploadsSlideRight(){
    this.uploadsOffset += 3;

    //@ts-ignore
    this.loadPatientUploads(this.selectedGoal.goal_id);

  }

  uploadsSlideLeft(){
    this.uploadsOffset -= 3;
    //@ts-ignore
    this.loadPatientUploads(this.selectedGoal.goal_id);
  }

}
