import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-receptionist-home',
  templateUrl: './receptionist-home.component.html',
  styleUrls: ['./receptionist-home.component.scss']
})
export class ReceptionistHomeComponent implements OnInit {

  constructor(private dataService: DataService) { }
  data:any = {status:0, upcoming_appointments:[], date:"", };
  recent:any = {recent_patients:[]};
  limit = 10;
  recentLimit = 10;
  departments = [];
  dept = 0;
  ngOnInit() {
  	this.loadReception();
    this.loadRecent();

    this.dataService.getDepts().subscribe( (data: any[] ) =>{
      this.departments = data;
    });


  }

  loadReception(){

    
  	this.dataService.getReception(this.limit, this.dept).subscribe( (data: any[] )=> {
  		this.data = data;

  	});

  }

  loadRecent(){
    this.dataService.getReceptionRecent(this.recentLimit).subscribe( (data: any[] )=> {
      this.recent = data;
      

    });
  }

  markPresent(id) {
    
    var data = {"appointment_id":id};
    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
        if(res.status == 0) {
            
            this.loadReception();
            
            
          } else {
            alert(res.msg);
          }
      } );

  }

  markAbsent(id){
    var data  = {"id":id};
    this.dataService.postMarkAbsent(data).subscribe( (res: any) => {
      if(res.status == 0){
        this.loadReception();
      } else {
        alert(res.msg);
      }

    });
  }

  loadMore() {
    this.limit +=10;
    this.loadReception();
  }

  loadMoreRecent() {
    this.recentLimit +=10;
    this.loadRecent();
  }

  filterDepartment(dept){
    this.dept = dept;
    this.loadReception();
  }

}
