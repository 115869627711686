//@ts-nocheck
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ffq',
  templateUrl: './ffq.component.html',
  styleUrls: ['./ffq.component.scss']
})
export class FfqComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = {};
  selectedCategory = "";

  ngOnInit() {

  	this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();
  }

  loadData(){
  	this.dataService.getDieticsFfq(this.id).subscribe( (data: any[] )=> {
  		this.data = data;
  		console.log('data-food', data);
      
  	} );
  }

  updateColumn(food, column, value){
    let data = {patient_id:this.id, food:food.id, column:column, value:value};
    console.log('value', value);
    
    food.frequency = 0;
    

    this.dataService.postDieticsFfq(data).subscribe( (res:any) => {
      if(res.status == 0) {
        //
      } else {
        alert(res.msg);
      }


    });

  }

  selectCategory(cat){
    this.selectedCategory = cat;
  }

  submitItem(data){

    data.category = this.selectedCategory;

    this.dataService.postDieticsFfqItem(data).subscribe( (res:any) => {
      if(res.status == 0) {
        $("#modal-new").modal("hide");
        this.loadData();
      } else {
        alert(res.msg);
      }


    });
  }

  removeItem(food, cat, index){
    let data = {patient_id:this.id, food:food.id};
    //@ts-ignore
    this.data.data[cat].splice(index, 1);
    
    this.dataService.postRemoveDieticsFfq(data).subscribe( (res:any) => {
      if(res.status == 0) {
        this.loadData();
      } else {
        alert(res.msg);
      }


    });
  }

}
