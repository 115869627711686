import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-staff-details-list',
  templateUrl: './staff-details-list.component.html',
  styleUrls: ['./staff-details-list.component.scss']
})
export class StaffDetailsListComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {data:[]};
  limit = 20;
  search = "";

  ngOnInit() {
    this.loadData();
  }

  loadData(){
  	this.dataService.getSdList().subscribe( (data: any[] )=> {
	  		this.data = data;
	  		
	  	});
  }

  csvDownload(){
    let key = localStorage.getItem("key");
    window.open(this.dataService.REST_API_SERVER + "view/export_sd&key="+key);
  }


}
