import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-dishcharge-form',
  templateUrl: './dishcharge-form.component.html',
  styleUrls: ['./dishcharge-form.component.scss']
})
export class DishchargeFormComponent implements OnInit {

  isBasicMedical = false;
  data:any;
  userdata: any = {};
  id = 0;
  dischargeForm: FormGroup;
  nipmr_id: string= '';

  
  constructor(
    private router:Router, 
    private route: ActivatedRoute, 
    private dataService: DataService, 
    private formBuilder: FormBuilder) {
      this.dischargeForm = formBuilder.group({
        nipmr_id: ['', Validators.required],
        date_of_birth: ['', Validators.required],
        name: ['', Validators.required],
        main: ['', Validators.required],
        history_of_presenting: ['', Validators.required],
        past_medical_history: ['', Validators.required],
        family_history: ['', Validators.required],
        antenatal_history: ['', Validators.required],
        birth: ['', Validators.required],
        gross: ['', Validators.required],
        speech: ['', Validators.required],
        socio_adaptive: ['', Validators.required],
        behaviour: ['', Validators.required],
        learning: ['', Validators.required],
        fine_motor: ['', Validators.required],
        adl: ['', Validators.required],
        sensory: ['', Validators.required],
        vision: ['', Validators.required],
        hearing: ['', Validators.required],
        diet_feeding: ['', Validators.required],
        sleep: ['', Validators.required],
        immunization: ['', Validators.required],
        electronic_media_exposure: ['', Validators.required],
        investigations_or_reports: ['', Validators.required],
        medications: ['', Validators.required],
        physical_examination: ['', Validators.required],
        impression: ['', Validators.required],
        course_of_the_program: ['', Validators.required],
        currentStatus: ['', Validators.required],
        developmental_status: ['', Validators.required],
        speech_and_communication: ['', Validators.required],
        ssp_result: ['', Validators.required],
        at_the_time_of_admission: ['', Validators.required],
        his_treatment_consisted_mainly: ['', Validators.required],
        informal_assessment_showed: ['', Validators.required],
        at_the_time_of_discharge: ['', Validators.required],
        ha_home_programme_given_monther: ['', Validators.required],
        socio_adaptive_changes: ['', Validators.required],
        adl_changes: ['', Validators.required],
        sensory_changes: ['', Validators.required],
        toilet_training_changes: ['', Validators.required],
        psychometric_assessment_and_behavior_status: ['', Validators.required],
        learning_academic_sped: ['', Validators.required],
        dietary_status: ['', Validators.required],
        academic_officer: ['', Validators.required],
        social_worker_notes: ['', Validators.required],
        recommendations: ['', Validators.required],
        recommendations_name: ['', Validators.required],
        recommendations_date: ['', Validators.required],
        recommendations_signature: ['', Validators.required],
      })
    }

 

  ngOnInit() {

    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];
    });

    this.loadProfile();

    $( function() {
      (<any>$(".date-picker" )).datepicker({
          dateFormat: "dd-mm-yy",
          beforeShow: function() {
              setTimeout(function(){
                  $('.ui-datepicker').css('z-index', 99999999999999);
              }, 0);
          },
          onSelect: function (dateText, inst) {
          this.focus();
          }
      });
    });

  }

  submitForm = (f: NgForm) => {
    const {valid, value} = f;
    if(valid){
      this.dataService.postDishchargeForm(value).subscribe( (res:any) => {
        console.log(res);
        if(res.status == 0) {
            //@ts-ignore
            alert(res.msg);
            this.dischargeForm.reset();
          } else {
            alert(res.msg);
          }
      } );
    }
  }

  loadProfile(){
		

    this.dataService.getPatientProfile(this.id).subscribe( (data: any[] )=> {
      // this.userdata = data;
      this.data = data;
      this.userdata.nipmr_id = this.data.op_number;
      this.userdata.date_of_birth = this.data.dob;
      this.userdata.name = this.data.patient_name;
      console.log(this.data);
    } );

}

}
