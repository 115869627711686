import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { SocketioService } from '../../../socketio.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hometherapy',
  templateUrl: './hometherapy.component.html',
  styleUrls: ['./hometherapy.component.scss']
})
export class HometherapyComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private socketService: SocketioService) { }

  data = [];
  activities = [];
  uploads = [];
  id = 0;
  runChat:any;
  selectedGoal = [];
  tasks = [];
  steps = [];
  selectedTab = 'tasks';

  selectedActivity = 0;
  
  video = {};
  noVideoText = "Loading video";

  chatState = 0;

  goal_title = "";
  goal_description = "";
  goal_id = 0;
  session_length = 1;

  ngOnInit() {

    
  	this.route.parent.params.subscribe((params:any) => { 
	      this.id = +params["id"];

	    });

  	//this.loadChat();
    //this.runChat = window.setInterval(() => this.loadChat(), 5000);

    //get activities

    this.loadActivities();
    this.loadUploads();
    var selectedVideo = this.video;


    let context = this;
    //@ts-ignore
    $('#modal-player').on('hidden.bs.modal', function (e) {
      //@ts-ignore
      $('#modal-player video').attr("src", '');
      //@ts-ignore
      this.video = {};
      //@ts-ignore
      this.noVideoText = "Loading video";

    });


    /* Assign goal:  Autocomplete */

    
    $('body').on('focus', '#ag-title', function(){
      let moi = $(this);
    
      //@ts-ignore
      moi.autocomplete({
        appendTo: '#modal-assign-goal',
        source: function(request, response) {
            //@ts-ignore
            $.ajax({
              //@ts-ignore
              url:baseURL + 'hometherapy/goal_ac',
              type:'GET',
              dataType:'json',
              data: {term: request.term},
              headers: {key:localStorage.getItem('key')},
              success: function(responseData) {
                
                var array = responseData.map(function(element){
                  return {value: element['title'] , id: element['id'], title: element['title'], description: element['description']};
                });
                response(array);

              }
            })
          }, 
          select: function(event, ui) {
              moi.val(ui.item.title);
              $('#ag-description').val(ui.item.description);
              context.goal_title = ui.item.title;
              context.goal_description = ui.item.description;
              context.goal_id = ui.item.id;
               
               $('#ag-title').attr('disabled', 'true');
               $('#ag-description').attr('disabled', 'true');

              return false;
          }
      });
    });


  }

  ngOnDestroy() {
      if (this.runChat) {
        clearInterval(this.runChat);
      }
  }

  loadChat(){
  	this.dataService.getChat(this.id).subscribe( (data: any[] )=> {
  		this.data = data;
  		
  	});
  }

  loadActivities(){
    this.dataService.getHtActivities(this.id).subscribe( (data: any[] )=> {

      this.activities = data;
      

      if (this.selectedActivity == 0) {
        //@ts-ignore  
        this.selectedActivity = data.activities[0].activity_id;
      }
      
      
    });
  }

  loadUploads(){
    console.log(this.id);
    this.dataService.getUploads(this.id).subscribe( (data: any[] )=> {
      this.uploads = data;
      console.log(data);
    });
  }

  sendChat(data) {
    data.patient_id = this.id;

    this.dataService.postSendChat(data).subscribe( (res:any) => {
        if(res.status == 0) {
            $('#chat_text').val('');
            this.loadChat();
            
            
          } else {
            alert(res.msg);
          }
    });

  }

  viewActivity(activity_id) {

    //load goal info
    this.dataService.getGoal(activity_id).subscribe( (data: any[] )=> {
      this.selectedGoal = data;
      
    });

    //load daily tasks

    this.dataService.getDailyTasks(activity_id).subscribe( (data: any[] )=> {
      this.tasks = data;
      console.log(data);
      
    });

    //load howto

    this.dataService.getHowto(activity_id).subscribe( (data: any[] )=> {
      this.steps = data;
      console.log(data);
      
    });

  }

  selectTab(val) {
    this.selectedTab = val;
  }

  assignGoal(data) {
    data.patient_id = this.id;
    data.goal_id = this.goal_id;
    console.log(data);

    //@ts-ignore
    $('#btn-assign').attr('disabled', 'true');
    
    
    this.dataService.postAssignGoal(data).subscribe( (res:any) => {
        //@ts-ignore
        $('#btn-assign').removeAttr('disabled');
        if(res.status == 0) {
            //@ts-ignore
            document.getElementById("formGoal").reset();
            $('#modal-assign-goal').modal('hide');
            this.loadActivities();
            
            
          } else {
            alert(res.msg);
          }
    });


    
    
  }

  selectActivity(id) {
    this.selectedActivity = id;
  }

  markComplete(id) {
    this.selectedActivity = id;
    var data = {"activity_id": id};

    this.dataService.postMarkActComplete(data).subscribe( (res:any) => {
      console.log(res);
        if(res.status == 0) {
            this.loadActivities();


          } else {
            alert(res.msg);
          }
    });
  }

  loadVideo(id){
    this.dataService.getVideo(id).subscribe( (data: any[] ) =>{
        this.video = data;

        //@ts-ignore
        if (data.status == 1) {
          //@ts-ignore
          this.noVideoText = data.msg;
        }

    });
  }


  toggleChat(){
    if (this.chatState == 0) {
      this.chatState = 1;
    } else {
      this.chatState = 0;
    }
  }


  initiateAssign() {
    $('#ag-title').removeAttr('disabled');
    $('#ag-description').removeAttr('disabled');
  }

  startChat(){
    var roomId = 13;
    this.socketService.joinRoom(roomId);

    var sender = {
      "id":10, 
      "name":"Lester Nygaard",
      "avatar": "https://randomuser.me/api/portraits/men/76.jpg"
    };

    var recipient = {
      "id":14, 
      "name":"Dr. Maya Bose Vinod",
      "onesignal_id":0
    };

    var msg = {
                
                  text:"Hello from Lester",
                  roomId: roomId,
                  user: sender
              };

    var data = {
                  from: {
                    name: sender.name,
                      userId: sender.id,
                        },
                  to: {
                    name: recipient.name,
                      userId: recipient.id,
                        onesignalId: recipient.onesignal_id,
                        },
                  roomId: roomId,
                    message: msg,
                      token: '1gwqkkD4vckeLt_QPU_PGl4BCQyFDkKF',
                        pushContent: '',
                };

    

    this.socketService.sendMessage(data);

  }

  

}
