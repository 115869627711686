import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ros',
  templateUrl: './ros.component.html',
  styleUrls: ['./ros.component.scss']
})
export class RosComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = {};
  page = 'Page 2';

  ngOnInit() {
  	this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadForm();
  }

  loadForm(){
  	this.dataService.getDietics2(this.id).subscribe( (data: any[] )=> {
  		this.data = data;
  		console.log('data', data);
    
  	} );
  }

  updateResponse(question, response){
  	this.data[question] = response;
  	
  }

  saveForm(){
  	$('.btn-submit').attr('disabled', 'true');
  	console.log(this.data);
  	this.dataService.postSaveDietics2(this.data).subscribe( (res:any) => {
      $('.btn-submit').removeAttr('disabled');
      console.log(res);
      if(res.status == 0) {
        alert(res.msg);
        //this.loadForm();
      } else {
        alert(res.msg);
      }


    });

  }

  selectStool(val) {
  	//@ts-ignore
  	this.data.stool = val;
  }

}
