import { Component, OnInit, OnDestroy} from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-symptoms-diagnosis',
  templateUrl: './symptoms-diagnosis.component.html',
  styleUrls: ['./symptoms-diagnosis.component.scss']
})
export class SymptomsDiagnosisComponent implements OnInit, OnDestroy {

  constructor( private router:Router, private route: ActivatedRoute, private dataService: DataService, private formBuilder: FormBuilder) { }

  data:any = {data:{impressions:''}};
  id = 0;
  role = localStorage.getItem('role_id');
  dept = localStorage.getItem('department');
  user_id = localStorage.getItem('user_id');
  showChd = 0;
  doctors = [];
  selectedDoc = 0;
  runAutoSave:any;
  autoSavedSd = 0;
  autoSaveData = {};
  unsavedSd = 0;

  ngOnInit() {

  	this.route.parent.params.subscribe((params:any) => { 
	      this.id = +params["id"];

	    });

  	this.loadSymptoms();
    this.loadSd();
    //@ts-ignore
    
    $('.rich-editor').trumbowyg({
      btns: [

            
            ['formatting'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            //['removeformat'],
            
        ]
    });
    

    // calling autosave
    
    //this.runAutoSave = window.setInterval(() => this.autoSave(), 2000);

    let context = this;
    
    $('body').on('keyup', '.trumbowyg-editor', function(e){
      //e.stopImmediatePropagation();
      var key = $(this).parent().parent().attr('id');
      context.syncSd( key, $(this).html() );
    });



    
    
  }

  ngOnDestroy() {
    this.id = 0;
    $('.trumbowyg-editor').unbind();

    if (this.runAutoSave) {
      clearInterval(this.runAutoSave);
    }
  }

  loadSymptoms() {
  	this.dataService.getSymptoms(this.id, this.selectedDoc).subscribe( (data: any[] )=> {
	      this.data = data;

        //@ts-ignore
        this.selectedDoc = data.selected_doc;
        //@ts-ignore
        //this.data.data.impressions = "";
        
        
	      
	      
	    } );
  }

  

  switchDoc(val) {
    this.selectedDoc = val;
    this.loadSymptoms();
  }

  submitSymptoms() {
  	$('#btn-submit').attr('disabled', 'true');
  	$('#btn-submit').text('Updating');
  	var data= {
  		"patient_id":this.id,
      "sd_id": this.autoSavedSd,
  		"dev_observation": $('#dev_observation').val(),
  		"neuro_examination": $('#neuro_examination').val(),
  		"goals": $('#goals').val(),
      "impression": $('#impression').val(),
      "others": $('#others').val(),
      "chd_notes": $('#chd-notes').val(),
      "rs_notes": $('#rs-notes').val(),
  		"intervention":$('#intervention').val(),
      "dt_background":$('#dt_background').val(),
      "dt_observation":$('#dt_observation').val(),
      "dt_assessment":$('#dt_assessment').val(),
      "dt_diagnosis":$('#dt_diagnosis').val(),
      "dt_intervention":$('#dt_intervention').val(),
      "dt_followup":$('#dt_followup').val(),
      
  	};

    
    var impressions = [];
    $('.impressions li.active').each(function(){

      impressions.push( $(this).text() );
    });

    //@ts-ignore
    data.impressions = impressions;

  	this.dataService.postSymptoms(data).subscribe( (res:any) => {
  		$('#btn-submit').removeAttr('disabled');
  		$('#btn-submit').text('Save');	
      if(res.status == 0) {
        this.clearSd();
        alert(res.msg);
        //@ts-ignore
        document.getElementById("formSymptoms").reset();
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }


    });

    


  }

  toggleNotes(val) {

    if(val == 'chd') {
      if (this.showChd == 1) {
        this.showChd = 0;
      } else {
        this.showChd = 1;
      }
    } else if(val == 'rs') {
      //@ts-ignore
      if (this.showRs == 1) {
        //@ts-ignore
        this.showRs = 0;
      } else {
        //@ts-ignore
        this.showRs = 1;
      }
    }

    

  }

  autoSave(){

    if (this.checkIfEmpty() == 0 ) {
      var data= {
        "patient_id":this.id,
        "sd_id": this.autoSavedSd,
        "dev_observation": $('#dev_observation').val(),
        "neuro_examination": $('#neuro_examination').val(),
        "goals": $('#goals').val(),
        "impression": $('#impression').val(),
        "others": $('#others').val(),
        
        
      };


      if (JSON.stringify(this.autoSaveData) !== JSON.stringify(data)   ) {
        this.autoSaveData = data;

        this.dataService.postAutoSaveSymptoms(data).subscribe( (res:any) => {
          
          if(res.status == 0) {
            this.autoSavedSd = res.sd_id;

          } else {
            console.log(res.msg);
          }


        });
      }

      

    }
    
  }

  checkIfEmpty(){
    var status = 1;
    if ($('#neuro_examination').val() != "" ) { status = 0; }
    if ($('#dev_observation').val() != "" ) { status = 0; }
    if ($('#goals').val() != "" ) { status = 0; }
    if ($('#impression').val() != "" ) { status = 0; }
    if ($('#intervention').val() != "" ) { status = 0; }
    if ($('#others').val() != "" ) { status = 0; }
    return status;

  }

  syncSd(key, val){
    
    var sd = JSON.parse(localStorage.getItem('sdStorage'));
    if (sd[this.id] === undefined) {
      sd[this.id] = {rh:''};
    } 
    
    sd[this.id][key] = val;
    
    localStorage.setItem('sdStorage', JSON.stringify(sd));
  }

  loadSd(){
    $('.sdPage .trumbowyg-editor').html('');
    if (localStorage.getItem("sdStorage") !== null) {
      var sd = JSON.parse(localStorage.getItem("sdStorage"));

      if (sd[this.id] != undefined ) {
         
        if (sd[this.id].rh != undefined ||  sd[this.id].rh != null ) {
          $('#rh .trumbowyg-editor').html(sd[this.id].rh);
          $('#rh .trumbowyg-editor').focus();
        }        

        if (sd[this.id].do != undefined ||  sd[this.id].do != null ) {
          $('#do .trumbowyg-editor').html(sd[this.id].do);
          $('#do .trumbowyg-editor').focus();
        }

        if (sd[this.id].pe != undefined ||  sd[this.id].pe != null) {
          $('#pe .trumbowyg-editor').html(sd[this.id].pe);
          $('#pe .trumbowyg-editor').focus();
        }

        if (sd[this.id].imp != undefined ||  sd[this.id].imp != null) {
          $('#imp .trumbowyg-editor').html(sd[this.id].imp);
          $('#imp .trumbowyg-editor').focus();
        }

        if (sd[this.id].plan != undefined ||  sd[this.id].plan != null) {
          $('#plan .trumbowyg-editor').html(sd[this.id].plan);
          $('#plan .trumbowyg-editor').focus();
        }

        if (sd[this.id].oth != undefined ||  sd[this.id].oth != null) {
          
          $('#oth .trumbowyg-editor').html(sd[this.id].oth);
          $('#oth .trumbowyg-editor').focus();
        }
        

        if (sd[this.id].rh != undefined || sd[this.id].do != undefined || sd[this.id].pe != undefined || sd[this.id].imp != undefined || sd[this.id].plan != undefined || sd[this.id].oth != undefined) {
          this.unsavedSd = 1;
        }
        
      }


    }
  }

  clearSd(){
    if (localStorage.getItem("sdStorage") !== null) {
      var sd = JSON.parse(localStorage.getItem("sdStorage"));

      if (sd[this.id] !== undefined ) {
        sd[this.id] = {};
        localStorage.setItem('sdStorage', JSON.stringify(sd) );
      }
    }
  }


}
