import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-schedule-individual',
  templateUrl: './schedule-individual.component.html',
  styleUrls: ['./schedule-individual.component.scss']
})
export class IndividualScheduleComponent implements OnInit {

 	constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }

	data = [];
	customDate = 0;
	date:any;
	status = '';
	reschedule=[];
	therapists=[];
	selectedTherapist=localStorage.getItem('user_id');
	selectedDate = 0;
	rescheduleDate= "";
	freeSlots = [];
	rescheduleId:any;
	departments = [];
	regSlot=0;
	regConsultant=0;
	regDate:any;
	repeatType = 0;
	repeatCount = 2;
	selectedSlot = {"visits":[], "notes":[], "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
	upcoming = [];
	cancelMode = 0;
	cancelUpcoming = 0;
	allow_edit = localStorage.getItem('allow_edit');
	user_id = localStorage.getItem('user_id');
	groupSlots = [];
	consultations = [];
	consultationDate:any;
	runSchedule:any;
	role = localStorage.getItem('role_id');
	cancelView = 0;

	/* for viewSlot */

	selectedMonth = "";
	cancelSlotId = "";
	cancelFrom = '0';
	openSlot = {"slot_id":"", "therapist_id":""}; //slot that's currently open for viewing.
	other_appointments = [];
	other_months = [];
	appointmentsMode = 1;

	selectedVisit = {"upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
	
  	ngOnInit() {
  		
  		
  		if (this.route.snapshot.paramMap.get('id') !== null && this.route.snapshot.paramMap.get('id') !== undefined ) {
  			this.selectedTherapist = this.route.snapshot.paramMap.get('id');
  		}

  		
  		

  		this.loadSchedule(this.date);
  		//this.runSchedule = window.setInterval(() => this.loadSchedule(this.date), 60000);
  		//datepicker
		
		$( function() {
			(<any>$("#reschedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	beforeShow: function() {
			        setTimeout(function(){
			            $('.ui-datepicker').css('z-index', 99999999999999);
			        }, 0);
			    },
			    onSelect: function (dateText, inst) {
					this.focus();
			    }
			});
		});

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});
  	}

  	ngOnDestroy() {
	  if (this.runSchedule) {
	    clearInterval(this.runSchedule);
	  }
	}

  	loadSchedule(date) {
		
		this.date = date;
		var cancelView = 1;
		if (this.role == '5' ) {
			cancelView = this.cancelView;
		}
		
		this.dataService.getIndividualSchedule(date, this.selectedTherapist, cancelView).subscribe( (data: any[] )=> {
			this.data = data;
			//@ts-ignore
			this.date = data.date;
			
			
		} );
		
	}
	showDetails(id, count){
		 
		this.selectedSlot = {"visits":[], "notes":[], "upcoming":[], "upcoming_months":[], "past":[], "past_months":[]};
		this.dataService.getVisitDetails(id).subscribe( (data: any[] )=> {
			//@ts-ignore
			this.selectedSlot = data.data;
			
		} );

		

		if (count == 1) {
			this.groupSlots = [];
			//get upcoming appointments;
			this.dataService.getUpcoming(id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.upcoming = data.data;
				
			} );
		} else {
			//get group appointments;
			this.upcoming = [];
			this.dataService.getGroupAppointments(id).subscribe((data: any[] )=>{
				//@ts-ignore
				this.groupSlots = data;
				
				
			});
		}
		
		
	}

	openRegistration(date){
		$('#inputRegNo').val( $('#inputNameApp').val() );
		this.regDate = date;
		
		
	}

	openAppointment(consultant, slot, selected){

		this.regConsultant = consultant;
		this.regSlot = slot;
		
		$('#inputConsultant').val(consultant);
		$('#inputSlotId').val(slot);
		//@ts-ignore
		$('#inputDate').val(this.data.dates[selected].date);
		
		
	}

	cancelAppointment(type, appointment_id) {
		var cnf = confirm("Are you sure you want to cancel this appointment?");

		if(cnf) {
			this.dataService.cancelAppointment(type, appointment_id).subscribe( (res:any) => {
				if(res.status == 0) {
		        	alert(res.msg);
		        	this.loadSchedule(this.date);
		        
		      	} else {
		        	alert(res.msg);
		      	}
			} );
		}


	}

	loadReschedule(appointment_id) {
		$('#rescheduleModal').modal('show');
		this.rescheduleId = appointment_id;
		this.dataService.loadReschedule(appointment_id).subscribe( (data: any[] )=> {
			this.reschedule = data;
			
		} );
	}

	getTherapists(){
		this.dataService.getTherapists().subscribe( (data: any[] )=> {
			this.therapists = data;
			
		} );	
	}

	setRescheduleDate(val) {
		
		/*if (moment(val, 'DD-MM-YYYY',true).isValid()) {
			
		}*/
		this.rescheduleDate = val;
		this.getSlots(); 

		
	}

	changeTherapist(val){
		this.selectedTherapist = val;
		this.getSlots();
	}

	getSlots() {
		this.dataService.getSlots(this.rescheduleDate, this.selectedTherapist).subscribe( (res: any["status"] )=> {
			if (res.status==0) {
				this.freeSlots = res.slots;
			}
			
		} );	
	}

	rescheduleSlot() {
		var data = {
			"date":this.rescheduleDate,
			"therapist":this.selectedTherapist,
			"appointment_id":this.rescheduleId,
			"slot":"",
		};

		var selectedSlot = $('ul.rescheduleSlotSelector li.active').attr('data-slot');
		data.slot = selectedSlot;

		this.dataService.postReschedule(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        $('#rescheduleModal').modal('hide');
		        $('#reschedule-date').val('');
		        this.freeSlots = [];
		        this.loadSchedule(this.date);
		        this.viewVisit(this.rescheduleId);
		        
	      	} else {
	        	alert(res.msg);
	      	}

		} );

	
	}

	replace(slot_id) {
		$('#inputReplace').val(slot_id);
		$('#addAppointment').modal('show');
	}

	markHoliday(date) {
		var data = {"date":date};
		var cnf = confirm("Are you sure you want to mark this date as holiday?");

		if(cnf) {
			this.dataService.postMarkHoliday(data).subscribe((res:any) => {
			
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadSchedule(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}


		

	}

	markLeave(consultant) {
		var data = {"consultant":consultant, "date":this.date};
		var cnf = confirm("Are you sure you want to mark leave for this consultant?");

		if(cnf) {
			this.dataService.postMarkLeave(data).subscribe((res:any) => {
				if(res.status == 0) {
			        alert(res.msg);
			        this.loadSchedule(this.date);
			        
		      	} else {
		        	alert(res.msg);
		      	}
			});
		}

		
	}

	submitRegistrationSchedule(data) {
	    data.dob = $('#inputDOB').val();
	    $('#btn-reg').attr('disabled', 'true');
	    $('#btn-reg').text('Submitting');

	    var treatments = [];

	    $('ul.treatment-selector li').each(function(){
	      if( $(this).hasClass('active') ) {
	        treatments.push( $(this).text() );
	      }
	    })

	    data.treatments = treatments;
	    data.regSlot = this.regSlot;
	    data.regDate = this.regDate;
	    data.regConsultant = this.regConsultant;
	    data.is_appointment = 1;

	    

	    
	    
	    this.dataService.postRegistration(data).subscribe( (res:any) => {
	    	$('#btn-reg').removeAttr('disabled');
	    	$('#btn-reg').text('Submit');
	      
	      if(res.status == 0) {
	        alert(res.msg);
	        $('#addPatientSchedule').modal('hide');
	        //@ts-ignore
	        document.getElementById("formRegs").reset(); 
	        $('.treatment-selector li').each(function(){
	        	$(this).removeClass('active');
	        });
	        this.loadSchedule(this.date);
	        
	      } else {
	        alert(res.msg);
	      }

	    }); 

	    

	    
	}

	submitAppointment() {
		var data = {
	      "consultant_id": $('#inputConsultant').val(),
	      "patient_id": $('#inputPatientId').val(),
	      "slot_id": $('#inputSlotId').val(),
	      "visit_purpose": $('#inputPurpose').val(),
	      "date":$('#inputDate').val(),
	      "repeat_type": this.repeatType,
	      "repeat_count": this.repeatCount,
	    };



	    if(data.patient_id == "" || data.patient_id == 0 ) {
	      alert('Select patient from suggestions');
	    } else {

	    	
	      $('#btn-appointment').attr('disabled', 'true');
	      $('#btn-appointment').text('Submitting');
	      
	      this.dataService.postAppointment(data).subscribe( (res:any) => {
	      	$('#btn-appointment').removeAttr('disabled');
	      	$('#btn-appointment').text('Add Appointment');
	      	this.repeatType = 0;
	        if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
	          //@ts-ignore
	          document.getElementById("formApp").reset(); 
	          
	          this.loadSchedule(this.date);
	          
	        } else {
	          alert(res.msg);
	        }
	      });
	     
	    }

	    


	}

	deleteNote(note_id){

		if (localStorage.getItem('role_id') == '5' ) {
			var cnf = confirm("To delete this note, press OK below.");

			if(cnf) {
				var data = {"note_id":note_id};
				this.dataService.postDeleteSlotNote(data).subscribe((res:any) => {
				
					if(res.status == 0) {
				        //alert(res.msg);
				        this.loadSchedule(this.date);
				        //@ts-ignore
				        this.viewSlot(this.openSlot.slot_id,0, 1, 0, this.openSlot.slot_date);
				        
			      	} else {
			        	alert(res.msg);
			      	}
				});
			}
		}

		


	}

	submitSlotNote() {
		
		var data = {
			"note" : $('#inputSlotNote').val(),
			"slot_id" : $('#inputSlotId').val(),
			"date":$('#inputDate').val(),
			"consultant_id": $('#inputConsultant').val(),
		}

		$('#btn-slotnote').attr('disabled', 'true');
	    $('#btn-slotnote').text('Submitting');

		this.dataService.postSlotNote(data).subscribe( (res:any) => {
			$('#btn-slotnote').removeAttr('disabled');
	      	$('#btn-slotnote').text('Add Note');

	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#addAppointment').modal('hide');
	          //@ts-ignore
	          document.getElementById("formSlotNote").reset(); 
	          
	          this.loadSchedule(this.date);
	          
	        } else {
	          alert(res.msg);
	        }

		});
	}

	setRepeatType(val) {
		this.repeatType = val;
		
	}

	setRepeatCount(val){
		this.repeatCount = val;

	}

	

	switchDate(val) {

		if (val !== null) {
			this.customDate = val;
			this.date = val;
			this.loadSchedule(this.date);
		}
		
		
	}

	setCancelMode(val){
		this.cancelMode = val;
		
	}
	setGroupCancelMode(index, val) {
		//@ts-ignore
		this.groupSlots.group[index].cancelMode = val;
	}

	setCancelUpcoming(){
		if (this.cancelUpcoming == 0 ) {
			this.cancelUpcoming =1;
		} else {
			this.cancelUpcoming = 0;
		}


	}

	confirmCancel(){

		var data = {
			//@ts-ignore
			"appointment_id": this.cancelSlotId,
			"cancellation_mode": this.cancelMode,
			"cancel_upcoming":this.cancelUpcoming,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');

		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        this.loadSchedule(this.date);

		        //clear data
		        this.cancelMode = 0;
		        this.cancelUpcoming = 0;
		        //@ts-ignore
		        this.selectedSlot.is_cancelled = 1;
		        $('.btn-cancel-confirm').removeAttr('disabled');
				$('.btn-cancel-confirm').text('Confirm Cancel');
				//@ts-ignore
				this.viewVisit(this.cancelSlotId);	

				$('#cancelModal').modal('hide');
		        
	      	} else {
	        	alert(res.msg);
	      	}

	      

		});
		

	}

	confirmGroupCancel(index){
		var data = {
			//@ts-ignore
			"appointment_id": this.groupSlots.group[index].id,
			//@ts-ignore
			"cancellation_mode": this.groupSlots.group[index].cancelMode,
			"cancel_upcoming": this.cancelUpcoming,
			"clear_field":1,
		}

		

		
		$('.btn-cancel-confirm').attr('disabled', 'true');
		$('.btn-cancel-confirm').text('loading.');
		
		
		
		this.dataService.postCancelAppointment(data).subscribe( (res:any) => {

			if(res.status == 0) {
		        alert(res.msg);
		        this.loadSchedule(this.date);

		        $('.btn-cancel-confirm').removeAttr('disabled');
				$('.btn-cancel-confirm').text('Confirm Cancel');
				//@ts-ignore
				this.showDetails(this.selectedSlot.id,this.selectedSlot.count);
		        
	      	} else {
	        	alert(res.msg);
	      	}

		});
		

		
		
		
		
	}


	markPresent(id) {
    	
	    var data = {"appointment_id":id};
	    this.dataService.postMarkPresent(data).subscribe( (res:any) => {
	        if(res.status == 0) {
	        	//@ts-ignore
	            this.viewVisit(id);
	            this.loadSchedule(this.date);     
	          } else {
	            alert(res.msg);
	          }
	      } );

	}

	markDischarge(id) {
    	console.log(id);
	    var data = {"appointment_id":id};
	    var cnf = confirm("Are you sure you want to discharge this patient?");

	    if (cnf) {
	    	this.dataService.postMarkDischarge(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.showDetails(this.selectedSlot.id,this.selectedSlot.count);
		       		this.loadSchedule(this.date);     
		          } else {
		            alert(res.msg);
		          }
		    });
	    }

	    

	}

	loadConsultations(date){
		this.consultationDate = date;
	  	this.dataService.getConsultations(date,this.selectedTherapist).subscribe( (data: any[] )=> {
			this.consultations = data;
			
			
		} );
		
	}

	submitConsultation(){

	  	var data = {
	  		"patient_id": $('#inputConsultation2').attr('data-patient'),
	  		"date":this.consultationDate,
	  		"consultant":this.selectedTherapist,
	  		"type": $('#inputConsultation2').attr('data-type'),
	  	};

	  	this.dataService.postConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            
		       		this.loadSchedule(this.date);
		       		this.loadConsultations(this.consultationDate);
		       		$('#inputConsultation2').val('');
		       		$('#inputConsultation2').attr('data-patient', 0);
		          } else {
		            alert(res.msg);
		          }
		      } );

	  	


	  }

	  cancelConsultation(id){
	  	var cnf = confirm("Are you sure you want to cancel this consultation?");

	  	if(cnf){

	  		var data =  {"id":id};

	  		this.dataService.postCancelConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            
		       		this.loadSchedule(this.date);
		       		this.loadConsultations(this.consultationDate);
		       		
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	}

	  }

	  print(){
	  	window.print();
	  }

	  setCancelView(val){
	  	this.cancelView = val;
	  	this.loadSchedule(this.date);

	  }

	  /* new appointments view */

	  viewSlot(slot_id, selected, show_group, appointment_id, slot_date){

		console.log("slot_date", slot_date)

		this.openSlot.slot_id = slot_id;
		this.openSlot.therapist_id = this.selectedTherapist;

		if (show_group == 1) {

			this.dataService.getViewSlot(slot_id, this.selectedTherapist, slot_date, selected).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.selectedSlot = data;
				
				
				
			} );
			$('#slotModal').modal('show');

		}else {


			
			this.viewVisit(appointment_id);

			
		}

		


	}

	viewVisit(appointment_id){
		
		this.dataService.getViewVisit(appointment_id).subscribe( (data: any[] )=> {
				//@ts-ignore
				this.selectedVisit = data;
				//@ts-ignore
				this.other_appointments = data.upcoming;
				//@ts-ignore
				this.other_months = data.upcoming_months;
				//@ts-ignore
				this.selectedMonth = data.upcoming_months[0];
				this.appointmentsMode = 1;
				console.log(data);
				
			} );

		$('#detailsModal').modal('show');

	}

	switchAppointmentsMode(mode){
	  		this.appointmentsMode = mode;
	  		if (mode==1) {
	  			this.other_appointments = this.selectedVisit.upcoming;
	  			this.other_months = this.selectedVisit.upcoming_months;
	  			
	  		}else if(mode==2){
	  			this.other_appointments = this.selectedVisit.past;
	  			this.other_months = this.selectedVisit.past_months;

	  		}
	  }

	  setCancellation(val, from) {
		this.cancelSlotId = val;
		this.cancelFrom = from;

	}

	selectMonth(month){
	  	this.selectedMonth = month;
	  	$('#collapseExample').collapse('show');
	  }

}
