import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrls: ['./my-patients.component.scss']
})
export class MyPatientsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  	data:any = {patients:[], count:0};
  	role_id = '';
	key:any;
	limit = 20;
	search = "";
	department= 0;
	departments = [];
	edit = { 'treatment':'' };
	tags = [];
	tag = "";
	loading:any = 0;
	


  ngOnInit() {

  	this.key = localStorage.getItem('key');
	this.role_id = localStorage.getItem('role_id');

	this.tag = this.dataService.getLocalData("filterTag");

	this.loadPatients();
	this.loadDepartments();
	this.loadTags();

  }

  	loadPatients() {
		this.loading = 1;
		
		this.dataService.getMyPatients(this.limit, this.search, this.tag).subscribe( (data: any[] )=> {
  			this.data = data;
			this.loading = 0;
		
  		
  		} );
	}

	loadDepartments() {
		
		this.dataService.getDepts().subscribe( (data: any[] )=> {
	  		this.departments = data;
	  		
	  	} );
	}

	loadTags(){
		this.dataService.getTags().subscribe( (data: any[] )=> {
	  		this.tags = data;
	  		
	  	} );
	}

	loadMore() {
		this.limit +=20;
		this.loadPatients();
	}

	searchPatients(search) {
		this.search = search;
		this.loadPatients();
	}

	searchTags(tag){
		this.tag = tag;
		this.dataService.setLocalData("filterTag", tag);
		this.loadPatients();
	}

}
