import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})
export class StatisticsRevenueComponent implements OnInit {

  constructor(private dataService: DataService) { }
  period = 'week';
  dept = 0;
  category = 'ALL';

  periodTitle = 'Last Week';
  deptTitle = 'All departments';
  data = [];
  

  ngOnInit() {

  	this.loadData();

  }

  loadData(){
  	this.dataService.getStatRevenue(this.period, this.dept, this.category).subscribe( (data: any[] ) =>{
        this.data = data;
        console.log(this.data);
    	this.plotRevenue()
        
    });
  }

  plotRevenue(){

  	//@ts-ignore
  	var ctx = document.getElementById('revenue-graph').getContext('2d');

  	//@ts-ignore
  	if(window.bar != undefined) {
  		//@ts-ignore
  		window.bar.destroy(); 	
  	}

  	//@ts-ignore
  	window.bar = new Chart(ctx, {
	    // The type of chart we want to create
	    type: 'bar',

	    // The data for our dataset
	    data: {
	    	//@ts-ignore
		    labels: this.data.labels,
		    datasets: [{
		      label: 'Revenue',
		      //@ts-ignore
		      data: this.data.data,
		      backgroundColor: [
		        'rgba(255, 99, 132, 1)',
		        'rgba(54, 162, 235, 1)',
		        'rgba(255, 206, 86, 1)',
		        'rgba(75, 192, 192, 1)',
		        'rgba(153, 102, 255, 1)',
		        'rgba(255, 159, 64, 1)',
		        'rgba(255, 99, 132, 1)',
		        'rgba(54, 162, 235, 1)',
		        'rgba(255, 206, 86, 1)',
		        'rgba(75, 192, 192, 1)',
		        'rgba(153, 102, 255, 1)',
		        'rgba(255, 159, 64, 1)',
		        'rgba(255, 99, 132, 1)',
		        'rgba(54, 162, 235, 1)',
		        'rgba(255, 206, 86, 1)',
		        'rgba(75, 192, 192, 1)',
		      ],
		      borderColor: [
		        'rgba(255,99,132,1)',
		        'rgba(54, 162, 235, 1)',
		        'rgba(255, 206, 86, 1)',
		        'rgba(75, 192, 192, 1)',
		        'rgba(153, 102, 255, 1)',
		        'rgba(255, 159, 64, 1)',
		        'rgba(255,99,132,1)',
		        'rgba(54, 162, 235, 1)',
		        'rgba(255, 206, 86, 1)',
		        'rgba(75, 192, 192, 1)',
		        'rgba(153, 102, 255, 1)',
		        'rgba(255, 159, 64, 1)'
		      ],
		      borderWidth: 1
		    }]
		},

	    // Configuration options go here
	    options: {
	    	legend: {
	            display: false
	        },
	        scales: {
		        yAxes: [{
		            ticks: {
		                beginAtZero: true
		            }
		        }]
		    }
	    }
	});

  }

  

  setPeriod(period, title) {
  	this.period = period;
  	this.periodTitle = title;
  	this.loadData();
  }

  setDept(dept, title) {
  	this.dept = dept;
  	this.deptTitle = title;
  	this.loadData();
  }

  setCategory(category) {
  	this.category = category;
  	this.loadData();
  	
  }

}
