//@ts-nocheck
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.scss']
})
export class StaffDetailsComponent implements OnInit {

  constructor(private dataService: DataService) { }
  user:any = {};
  data:any = {}
  more_data:any = [];

  ngOnInit() {
    this.loadProfile();
    this.loadData();
  }

  loadProfile(){
  	this.dataService.getUserProfile().subscribe( (data: any[] )=> {
	  		this.user = data;
	  		
	  		
	  	} );
  }

  loadData(){
    this.dataService.getStaffDetails().subscribe( (data: any[] )=> {
      //@ts-ignore
      this.data = data.data;
      //@ts-ignore
      this.more_data = data.more_data;
      
    } );
  }

  submitForm(data){
    data.more_data = this.more_data;
    this.dataService.postStaffDetails(data).subscribe( (res:any) => {
      $('#btn-pwd').text('Submit');
      $('#btn-pwd').removeAttr('disabled');
      if(res.status == 0) {
        alert(res.msg);
        this.loadData();
      } else {
        alert(res.msg);
      }
    });

  }

  addNew(type){
    
    var newRow = {id:0, type:type, content:""};
    this.more_data[type].push(newRow);
    
  }

  addNewAc(){
    var newRow = {id:0, type:'8', content: {course:"", subject:"", year:""}};
    this.more_data['8'].push(newRow);
  }

  addNewWork(){
    var newRow = {id:0, type:'9', content: {title:"",type:"",company:"",location:"",start:"",end:"",salary:""}};
    this.more_data['9'].push(newRow);
  }


  addNewLang(){
    var newRow = {id:0, type:'10', content: {language:"", proficiency:""}};
    this.more_data['10'].push(newRow);
  }

  updateField(type, index, val){
    this.more_data[type][index].content = val;
    
  }

  updateJsonField(type, index, field, val){
    this.more_data[type][index].content[field] = val;
  }

  removeRow(type, i, id){
    this.more_data[type].splice(i, 1);

    if(id !=0) {
      let data = {id:id};

      this.dataService.postDeleteSdMore(data).subscribe( (res:any) => {
        
      });

    }

  }

}
