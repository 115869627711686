import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tug',
  templateUrl: './tug.component.html',
  styleUrls: ['./tug.component.scss']
})
export class TugComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = [];

  ngOnInit() {

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadTug();
  }

  loadTug(){
  	this.dataService.getTug(this.id).subscribe( (data: any[] )=> {
  		
      this.data = data;
      
      console.log('tug', data);
      
    } );
  }

  addEntry(data){
    data.patient_id = this.id;

    this.dataService.postAddTug(data).subscribe( (res:any) => {
        if(res.status == 0) {
            this.loadTug();
            //@ts-ignore
            document.getElementById("formEntry").reset();
          } else {
            alert(res.msg);
          }
      } );

  }

  updateAd(val){

  	var data = {patient_id:this.id, ad:val};

  	this.dataService.postTugAd(data).subscribe( (res:any) => {
        if(res.status == 0) {
            //this.loadTug();
            
          } else {
            alert(res.msg);
          }
      } );

  }

  deleteItem(id){
    var cnf = confirm("Do you really want to delete this entry?");

    if (cnf) {
      var data = {id:id};

      this.dataService.postDeleteTug(data).subscribe( (res:any) => {
        if(res.status == 0) {
            this.loadTug();
            
          } else {
            alert(res.msg);
          }
      } );

    }

  }

}
