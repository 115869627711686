import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mas',
  templateUrl: './mas.component.html',
  styleUrls: ['./mas.component.scss']
})
export class MasComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = [];
  

  ngOnInit() {

    

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadMas();

  }

  loadMas(){

  	this.dataService.getMas(this.id).subscribe( (data: any[] )=> {
  		
      this.data = data;
      
      console.log('mas', data);
      
    } );
  }

  addEntry(data){
    data.patient_id = this.id;

    this.dataService.postAddMas(data).subscribe( (res:any) => {
        if(res.status == 0) {
            this.loadMas();
            //@ts-ignore
            document.getElementById("formEntry").reset();
          } else {
            alert(res.msg);
          }
      } );

  }

  deleteItem(id){
    var cnf = confirm("Do you really want to delete this entry?");

    if (cnf) {
      var data = {id:id};

      this.dataService.postDeleteMas(data).subscribe( (res:any) => {
        if(res.status == 0) {
            this.loadMas();
            
          } else {
            alert(res.msg);
          }
      } );

    }

  }

}
