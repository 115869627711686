import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class StatisticsOverviewComponent implements OnInit {

  constructor(private dataService: DataService) { }


  data = [];
  departments = [];
  


  ngOnInit() {
  	this.getOverview(0);
    this.getDepts();

  	$('body').on('click', '.filter-options li', function(){
  		$('.filter-options li').removeClass('active');
  		$(this).addClass('active');
  	});
  	

  }

  getDepts(){
    this.dataService.getDepts().subscribe( (data: any[] ) =>{
        this.departments = data;
        
        
    });
  }

  getOverview(val) {
  	this.dataService.getStatOverview(val).subscribe( (data: any[] ) =>{
        this.data = data;
        //@ts-ignore
    	this.plotOverview(data.graphdata);    
        
    });
  }


  plotOverview(graphdata){
  	//@ts-ignore
  	var ctx = document.getElementById('overview-graph').getContext('2d');

  	//@ts-ignore
  	if(window.bar != undefined) {
  		//@ts-ignore
  		window.bar.destroy(); 	
  	}
	
	 

  	//@ts-ignore
  	window.bar = new Chart(ctx, {
	    // The type of chart we want to create
	    type: 'pie',

	    // The data for our dataset
	    data: {
	        labels: ['Normal', 'Premium'],
	        datasets: [{
	            
	            backgroundColor: ['orange', 'blue'],
	            borderColor: ['orange', 'blue'],
	            data:graphdata,
	        }],

	    },

	    // Configuration options go here
	    options: {
	    	legend: {
	            display: false
	        },
        plugins: {
          datalabels: {
                color: '#FFF'
            }
        }
	    },
      //@ts-ignore
      plugins: [ChartDataLabels],
	});

  	

  }

}
