import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dietics',
  templateUrl: './dietics.component.html',
  styleUrls: ['./dietics.component.scss']
})
export class DieticsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  title = "";
  id = 0;
  page = "";
  ngOnInit() {

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    
  	this.loadData();
    //console.log('route', this.router.url);

    switch (this.router.url) {
      case "/dashboard/patient/"+this.id+"/dietics/personal":

        this.page = "Page 1";
        break;

      case "/dashboard/patient/"+this.id+"/dietics/ros":
        this.page = "Page 2";
        break;

      case "/dashboard/patient/"+this.id+"/dietics/eat10":
        this.page = "Page 3";
        break;
        case "/dashboard/patient/"+this.id+"/dietics/iddsi":
          this.page = "Page 4";
          break;
      case "/dashboard/patient/"+this.id+"/dietics/anthropometric":
        this.page = "Page 5";
        break;

      case "/dashboard/patient/"+this.id+"/dietics/biochem":
        this.page = "Page 6";
        break;

      case "/dashboard/patient/"+this.id+"/dietics/ffq":
        this.page = "Page 7";
        break;
        case "/dashboard/patient/"+this.id+"/dietics/final":
          this.page = "Page 8";
          break;
      
      default:
        // code...
        break;
    }
  }

  loadData(){
  	this.dataService.getDieticsTitle(this.id).subscribe( (data: any[] )=> {
  		//@ts-ignore
	  	this.title = data.title;
	  	console.log('data', data);
	  } );
  }

}
