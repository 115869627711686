import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import * as OT from '@opentok/client';
//import { Socket } from 'ngx-socket-io';
import {environment} from './environment';
import { Observable } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class SocketioService {
	
	socket;
  session: OT.Session;
  token: string;
	
  constructor() {}

  setupSocketConnection(){
    
  	//this.socket = io(environment.SOCKET_ENDPOINT);
  	this.socket = io.connect(environment.SOCKET_ENDPOINT, {
	    transports: ['websocket'],
	    query: {token: localStorage.getItem('key')},
	  });

  	this.socket.on('connect', () => {
	    console.log('connected');
  	});

  	this.socket.on('connect_error', () => {
  	    //console.log('connection error');
  	});

  	this.socket.on('disconnect', () => {
  	    console.log('disconnected');
  	});
    this.socket.on('chat', () => {
        
        //console.log(message);
    });
    

    	
  }

  getOT(){
    return OT;
  }



  initSession(sessionId, token){
    
    this.session = this.getOT().initSession(environment.OT_KEY, sessionId);
    this.token = token;
    
    return Promise.resolve(this.session);    
    

  }

  connectTok(){
    return new Promise( (resolve, reject) => {
      this.session.connect(this.token, (err)=> {
        if (err) {
          reject(err);
          console.log('error from socket service', err);
        } else {
          resolve(this.session);
        }
      })
    } )
  }

  

  sendMessage(message) {

  	this.socket.emit('chatmessage', message);	
    
  }

  joinRoom(roomId) {
  	this.socket.emit('join-room', {roomId:roomId});	
  }

  leaveRoom(roomId) {
    this.socket.emit('leave-room', {roomId:roomId});  
  }

  rejectCall(roomId){
    this.socket.emit('rejectcall', {roomId:roomId});
  }

  
  

  onNewMessage(){
    return Observable.create(observer => {
      this.socket.on('chat', msg => {
        observer.next(msg);
        
      });
    });
  }

  /* videocall */

 

  public getOpenTokSession(userid){
    
    return Observable.create(observer => {
      this.socket.emit('getOpenTokSession', {userid}, (data:any) => {
        observer.next(data);
      });
    })

  }

  public joinOpenTokSession(userId, sessionId){
    return Observable.create(observer => {
      this.socket.emit('joinToOpenTokSession', {userId, sessionId}, (data:any) => {
        observer.next(data);
      });
    })
  }


  startVideoCall(data){
    
    this.socket.emit('startOpenTokVideoCall', {
      roomId: data.roomId,
      sessionId: data.sessionId,
      onesignalId: data.onesignalId,
      callType: 'video',
      callFromId: data.callFromId,
      callFromName: data.callFromName,
      callToId: data.callToId,
      callToName: data.callToName,
      content: '',
      token: data.token,
    });
  }

  

  //Listen for incoming video calls

  onNewVideoCall(){
    
    return Observable.create(observer => {
      this.socket.on('incommingvideocall', data => {
        observer.next(data);
        
      });
    });
  }

  onNewAudioCall(){
    
    return Observable.create(observer => {
      this.socket.on('incommingaudiocall', data => {
        observer.next(data);
        
      });
    });
  }

  onRejectCall(){
   return Observable.create(observer => {
      this.socket.on('rejectcall', data => {
        observer.next(data);
        console.log('rejected', data);
      });
    }); 
  }


  receiveSession(sessionId, sessionToken){
    if (environment.OT_KEY && sessionId && sessionToken) {
      this.session = this.getOT().initSession(environment.OT_KEY, sessionId);
      this.token = sessionToken;
      
      return Promise.resolve(this.session);
    }
  }
  

}
