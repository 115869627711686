import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { interval as observableInterval } from "rxjs";
import { takeWhile, scan, tap } from "rxjs/operators";

@Component({
  selector: 'app-uif',
  templateUrl: './uif.component.html',
  styleUrls: ['./uif.component.scss']
})
export class UifComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  data:any = {previous:'', next:''};
  forms:any = [];
  selectedForm:any = {id:0, form_name:''};
  nextForm:any = {id:0, form_name:''};
  previousForm:any = {id:0, form_name:''};

  ngOnInit() {
    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });
    this.loadForms();
  }

  loadForms(){
    this.dataService.getUnifiedForms(0, this.id, 40).subscribe( (data: any[] )=> {
        this.forms = data;
        console.log('forms', data);
        //@ts-ignore
        if (data.forms.length > 0) {
          
          //@ts-ignore
          this.loadForm(data.forms[0]);
          //@ts-ignore
          this.nextForm = data.forms[1];
          
        }

    });
  }

  loadForm(form){
    this.selectedForm = form;
    this.dataService.getUnifiedForm(form.id, this.id).subscribe( (data: any[] )=> {
      this.data = data;
      console.log('selectedForm', this.data);
      
  });
    
  }

  

}
