import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {status:0, tags:[]};
  role_id = '0';

  ngOnInit() {
    this.role_id = localStorage.getItem('role_id');
    this.loadData();
  }

  loadData(){
    this.dataService.getPatientTags().subscribe( (data: any[] )=> {
      this.data = data;
    });
  }

  submitPatientTag(data){
    $('.btn-add').addClass('disabled');
    this.dataService.postPatientTag(data).subscribe( (res:any) => {
      $('.btn-add').removeClass('disabled');
      
      if(res.status == 0){
        this.loadData();
        //@ts-ignore
        $("#modal-patient-tag").modal("hide");
      } else {
        alert(res.msg);
      }
      
    });

  }

  submitSymTag(data){
    $('.btn-add').addClass('disabled');
    this.dataService.postSymTag(data).subscribe( (res:any) => {
      $('.btn-add').removeClass('disabled');
      
      if(res.status == 0){
        this.loadData();
        //@ts-ignore
        $("#modal-sym-tag").modal("hide");
      } else {
        alert(res.msg);
      }
      
    });

  }

  deleteTag(tag, type){
    
    var data =  {tag:tag, type:type};
    var cnf = confirm("Do you want to delete this tag?");

    if(cnf){
      this.dataService.postDeletePatientTag(data).subscribe( (res:any) => {
      
      
        if(res.status == 0){
          this.loadData();
          
        } else {
          alert(res.msg);
        }
        
      });
    }

    

  }

}
