import { Component, OnInit, NgModule } from '@angular/core';
import { DataService } from '../data.service';
import { FormsModule }   from '@angular/forms';
import { Router } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }
  key = null;
  role_id = '0';
  ngOnInit() {
  	//
    this.key = localStorage.getItem('key');
    this.role_id = localStorage.getItem('role_id');

    if (this.key !== null) {
      console.log(this.role_id);
      
      if(this.role_id == '3' || this.role_id == '4' || this.role_id == '12') {
          
        //window.location.href = "./dashboard/home";
        this.router.navigate(['/dashboard/home']);

      } else if(this.role_id == '5') {
        
          //window.location.href = "./dashboard/receptionHome";
          this.router.navigate(['/dashboard/receptionHome']);
      } else if(this.role_id == '1') {
          this.router.navigate(['/dashboard/receptionHome']);
      } else if(this.role_id == '8') {
          this.router.navigate(['/dashboard/patient']);
      } else if(this.role_id == '9') {
          this.router.navigate(['/dashboard/patient']);
      } else if(this.role_id == '10') {
        this.router.navigate(['/dashboard/patient']);
      } else if(this.role_id == '11') {
        this.router.navigate(['/dashboard/analytics']);
      } 
      

    }

  }
  login(data) {
     $('#btn-login').attr('disabled', 'true');
     $('#btn-login').text('Logging in');
	   this.dataService.login(data).subscribe( (res:any) => {
       $('#btn-login').removeAttr('disabled');
       $('#btn-login').text('Login');
      if(res.status == 0) {
        localStorage.setItem( "key", res.key);
        localStorage.setItem("role_id", res.role_id);
        
        if (localStorage.getItem('key') == res.key ) {

          this.dataService.setLocalData('userId', res.user_id);
          this.dataService.setLocalData('roleId', res.role_id);
          

          if(res.role_id == 3 || res.role_id == 4 || res.role_id == 12) {
          
            //window.location.href = "./dashboard/home";
            this.router.navigate(['/dashboard/home']);

          } else if(res.role_id == 5) {
            
              //window.location.href = "./dashboard/receptionHome";
              this.router.navigate(['/dashboard/receptionHome']);
          }  else if(res.role_id == 1) {
            
              //window.location.href = "./dashboard/receptionHome";
              this.router.navigate(['/dashboard/receptionHome']);
          } else if(res.role_id == 8) {
              this.router.navigate(['/dashboard/patient']);
          } else if(res.role_id == '9') {
              this.router.navigate(['/dashboard/patient']);
          } else if(res.role_id == '10') {
            this.router.navigate(['/dashboard/patient']);
          } else if(res.role_id == '11') {
            this.router.navigate(['/dashboard/analytics']);
          } 


          
        }

        


      } else {
        alert(res.msg)
      }

     });
  }

}
