import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 



@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
	
	
	
  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private ngxService: NgxUiLoaderService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };
  }
  data:any = {activities:[]}; 
  files = [];
  id = 0;
  role_id= '0';
  user_id = '0';
  selectedFile = [];
  images = ['assets/img/loader.jpg'];  
  departments = [];
  mydept = "";
  selectedDept = "";
  selectedDeptId = 0;
  selectedDoc = 0;
  selectedRemarks = {id:0, title:'', contents:'', note_date:''};
  selectedSd = {};
  sdDisplay = {history:1, observation:0, examination:0, impression:0, plan:0, others:0};
  remarksHistory = [];
  sdHistory = [];
  remarksShowAll = 0;
  sdShowAll = 0;
  limit = 20;

  ngOnInit() {

    this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });
  	
  	this.role_id = localStorage.getItem('role_id');
    this.user_id = localStorage.getItem('user_id');
    this.loadOverview(0);
    this.getDepts();
    //@ts-ignore
    
    $('.rich-editor').trumbowyg({
      btns: [

            
            ['formatting'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            //['removeformat'],
            
        ]
    });
    
    

  }

  loadOverview(department){
    

    this.dataService.getPatientOverview(this.id, department, this.selectedDoc, this.limit).subscribe( (data: any[] )=> {
      this.data = data;
      
      
    } );

    this.dataService.getPatientFiles(this.id).subscribe( (data: any[] )=> {
      this.files = data;
      
    } );

    
    
  }

  loadMore(){
    this.limit += 20;
    this.loadOverview(this.selectedDeptId);
  }

  getDepts(){
    this.dataService.getDepts2().subscribe( (data: any[] ) =>{
        this.departments = data;
        //@ts-ignore
        this.selectedDept = data.mydept;
        
    });
  }

  openFile(key){
    this.ngxService.start('loader-01'); //progress
    this.dataService.getOpenFile(key).subscribe( (data:any )=> {
      
      if (data.status==0) {
        this.ngxService.stop('loader-01');
        window.open(data.url, '_blank');
      } else {
        this.ngxService.stop('loader-01');
        alert(data.msg);
      }
      
    } );

    
  }

  selectFile(file){
    this.selectedFile = file;
    
    
  }

  submitFileEdit(data) {
    $('#btn-file-edit').attr('disabled','true');
    $('#btn-file-edit').text('Updating');
    this.dataService.postEditFile(data).subscribe( (res:any) => {
      $('#btn-file-edit').removeAttr('disabled');
      $('#btn-file-edit').text('Save');
      if(res.status == 0) {
        $('#modalFileEdit').modal('hide');
        this.loadOverview(0);
      } else {
        alert(res.msg);
      }


    });
  }


  openViewer(id){
    

    this.dataService.getDocumentPages(id).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      if (data.status == 0) {
        
        //@ts-ignore   
        if (data.extn === 'jpg' || data.extn === 'jpeg' || data.extn === 'png' ) {
          $('#viewer').addClass('show');
          //@ts-ignore
          this.images = data.files;
        } else {
          //@ts-ignore
          window.open(data.files[0])
        }

        
      }
      
      
    } );

  }

  closeViewer(){
    $('#viewer').removeClass('show');
    this.images = ['../../../../assets/img/loader.jpg'];  
  }

  switchDept(dept_name, dept_id){
    //@ts-ignore
    this.selectedDept = dept_name;
    this.selectedDeptId = dept_id;
    if (dept_id == 'all') {
    	this.selectedDoc = 0;
    }
    this.limit = 20;
    this.loadOverview(dept_id);
  }

  switchDoc(dept_name, doc_id) {
    this.selectedDept = dept_name;
    this.selectedDoc = doc_id;
    this.loadOverview(0);
  }


  selectRemarks(item){
    
    //item.contents = item.contents.replaceAll("<br/>", "");
    //item.contents = item.contents.replaceAll("<br />", "");

    this.selectedRemarks = item;
    $('#note-edit-contents').html(item.contents);
    
    
  }

  updateNotes(data)  {
    data.id = this.selectedRemarks.id;
    data.note_contents = $('#note-edit-contents').html();
    $('#btn-ed-remarks').attr('disabled', 'true');
    this.dataService.postEditNotes(data).subscribe( (res:any) => {
      $('#btn-ed-remarks').removeAttr('disabled');
      if(res.status == 0) {
        alert(res.msg);
        $('#editRemarks').modal('hide');
        //this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
        this.loadOverview(0);
      } else {
        alert(res.msg);
      }


    });

  }

  selectSd(item) {

    this.selectedSd = item;
    /*
    item.relevant_history = item.relevant_history.replaceAll("<br />", "");
    item.developmental_observation = item.developmental_observation.replaceAll("<br />", "");
    item.physical_examination = item.physical_examination.replaceAll("<br />", "");
    item.impression = item.impression.replaceAll("<br />", "");
    item.plan = item.plan.replaceAll("<br />", "");
    item.others = item.others.replaceAll("<br />", "");
    */

    $('#neuro_examination').html(item.relevant_history);
    $('#dev_observation').html(item.developmental_observation);
    $('#goals').html(item.physical_examination);
    $('#impression').html(item.impression);
    $('#intervention').html(item.plan);
    $('#others').html(item.others);


  }

  updateSd(data){
    $('#btn-ed-sd').attr('disabled', 'true');
    //@ts-ignore
    data.id = this.selectedSd.id;
    data.relevant_history = $('#neuro_examination').html();
    data.developmental_observation = $('#dev_observation').html();
    data.physical_examination = $('#goals').html();
    data.impression = $('#impression').html();
    data.intervention = $('#intervention').html();
    data.others = $('#others').html();
    this.dataService.postUpdateSymptoms(data).subscribe( (res:any) => {
      $('#btn-ed-sd').removeAttr('disabled');
      if(res.status == 0) {
        alert(res.msg);
        $('#modalSd').modal('hide');
        //this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
        this.loadOverview(0);
      } else {
        alert(res.msg);
      }


    });
  }

  loadRemarksHistory(id, showAll){
    this.remarksShowAll = showAll;
    this.dataService.getRemarksHistory(id, showAll).subscribe( (data: any[] )=> {
      this.remarksHistory = data;
      
    } );    
  }

  loadSdHistory(id, showAll){
    this.sdShowAll = showAll;
    this.dataService.getSdHistory(id, showAll).subscribe( (data: any[] )=> {
      this.sdHistory = data;
      
    } );      

  }

  deleteFile(id){
     var data = {id:id};
     var cnf = confirm("Are you sure you want to delete this file?");

     if (cnf) {
      this.dataService.postDeleteFile(data).subscribe( (res:any) => {
      
        if(res.status == 0) {
          alert(res.msg);
          $('#modalSd').modal('hide');
          //this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
          this.loadOverview(0);
        } else {
          alert(res.msg);
        }


      });
     }

     

  }


}


