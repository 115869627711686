import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-biochem',
  templateUrl: './biochem.component.html',
  styleUrls: ['./biochem.component.scss']
})
export class BiochemComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id = 0;
  data = {status:0, biochem:[]};
  /*
  public lineChartData: ChartDataSets[] = [
    { data: [ {x:24,y:79.6}, {x:25, y:80.4}, {x:26,y:81.2}, {x:27,y:81.9} ], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      fill:false
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  */

  ngOnInit() {
  	this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();
  }


  	submitEntry(data){
      data.patient_id = this.id;

      this.dataService.postDieticsIntake(data).subscribe( (res:any) => {
        if(res.status == 0) {
          $('#modal-entry').modal('hide');
          //@ts-ignore
          document.getElementById("formEntry").reset(); 
          this.loadData();
        } else {
          alert(res.msg);
        }


      });


    }

    submitBiochem(data){
      data.patient_id = this.id;
      data.date = $('#test_date').val();
      console.log('data', data);
      
      this.dataService.postDieticsBiochem(data).subscribe( (res:any) => {
        if(res.status == 0) {
          $('#modal-biochem').modal('hide');
          //@ts-ignore
          document.getElementById("formBiochem").reset(); 
          this.loadData();
        } else {
          alert(res.msg);
        }


      });
      


    }

    saveForm(){
      //@ts-ignore
      let data = this.data.recall;
      data.patient_id = this.id;

      this.dataService.postDieticsRecall(data).subscribe( (res:any) => {
        if(res.status == 0) {
          alert(res.msg);
        } else {
          alert(res.msg);
        }


      });

      
    }

    loadData(){
    	this.dataService.getDieticsBiochem(this.id).subscribe( (data: any[] )=> {
        //@ts-ignore
	  		this.data = data;
	  		console.log('data', data);
	    
	  	} );
    }

    deleteIntake(id){
      let data = {id:id};

      var cnf = confirm("Do you really want to delete this entry?");

      if(cnf){
        this.dataService.postDeleteIntake(data).subscribe( (res:any) => {
          if(res.status == 0) {
            this.loadData();
          } else {
            alert(res.msg);
          }
  
  
        });
      }

    }

    deleteBiochem(id){
      let data = {id:id};

      var cnf = confirm("Do you really want to delete this entry?");

      if(cnf){
        this.dataService.postDeleteBiochem(data).subscribe( (res:any) => {
          if(res.status == 0) {
            this.loadData();
          } else {
            alert(res.msg);
          }
  
  
        });
      }

    }

}
