import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
	data:any = {bills:[], count:0};
	mode = "";
	search = "";
	limit = 20;
	offset=0;
	role_id = localStorage.getItem('role_id');
	
	date = "";
  	
	loadedBill = {
	status:0, 
	data:{
		bill_no:"", 
		patient:"",
		phone:"",
		city:"",
		country:"",
		bill_date:"",
		consultant:"",
		payment_mode:"",
		contents:[],
		total:"",
		reg_no:""
		}
	};
	selectedBill = {index:0, id:0};
	wideSearchStat = 0;
	selectedReportMode:any = 0;
	depts:any = [];

  	constructor(private dataService: DataService) { }

	ngOnInit() {

		this.date = this.dataService.localData.billingFilterDate;
		this.search = this.dataService.localData.billingFilterSearch;
		this.offset = this.dataService.localData.billingOffset;
	    this.loadBills();
		this.loadDepartments();

		let ref = this;

		//@ts-ignore
		$( "#date" ).datepicker({
	    	dateFormat: "dd-mm-yy",
	    	onSelect: function (dateText, inst) {
				
				ref.date = dateText;
				ref.dataService.localData.billingFilterDate = dateText;
				ref.loadBills();
		    }
		});

		


	}

	loadBills(){
		this.dataService.getBills(this.limit, this.offset, this.search, this.mode, this.date, this.wideSearchStat).subscribe( (data: any[] )=> {
	  		this.data = data;
	  	});
	}

	loadDepartments(){
		this.dataService.getDepts().subscribe( (data: any = {status:0, departments:[]} )=> {
		  if(data.status == 0){
			this.depts = data.departments;
		  }
		  console.log(this.depts);
		});
	  }

	setMode(mode){
		this.mode = mode;
		this.loadBills();
	}

	searchBills(search) {
		this.search = search;
		if(search == ''){
			this.wideSearchStat = 0;
		}
		this.dataService.localData.billingFilterSearch = search;
		this.loadBills();
	}

	loadMore() {
		this.limit +=20;
		this.loadBills();
	}
	

	


	  submitBill(){
	  	$('#btn-submit').attr('disabled', 'true');
    	$('#btn-submit').text('Submitting');

	    var data = {"patient_id": $('.patient-details').attr('data-patient') , "billcontents":[]};
	    var bc = [];

	    $('.ab-row').each(function(){
	      
	      var row = {
	        "description":$(this).find('.ab-item').val(),
	        "rate": $(this).find('.ab-rate').val(),
	        "qty": $(this).find('.ab-qty').val(),
	      }

	      bc.push(row);
	    });

	    data.billcontents = bc;
	    
	    this.dataService.postBill(data).subscribe( (res:any) => {

	      if(res.status == 0) {
	        alert(res.msg);
	        $('#modalBilling').modal('hide');

	        $('#btn-submit').removeAttr('disabled');
	        $('#btn-submit').text('Submit');
	        //$('#formBill')[0].reset();

	        this.loadBills();
	      } else {
	        alert(res.msg);
	      }

	    } );  
	    


	  }

	  markBillPaid(bill) {
		this.selectedBill = bill;
		$("#modal-payment").modal('show');
	  }

	  submitPaymentMode(data){
		if(data.mode != 0){
			data.bill_id = this.selectedBill.id;
			this.dataService.postMarkBillPaid(data).subscribe( (res:any) => {
			if(res.status == 0) {
			  	alert(res.msg);
				
				this.loadBills();
				$("#modal-payment").modal('hide');
			} else {
			  alert(res.msg);
			}
			} );
		} else {
			alert("Select a mode of payment to continue.");
		}
	  }

	showPatientSelect() {
		$('.patient-details').hide();
		$('.patient-details').attr('data-patient', 0);
		$('.input-select-patient').val('');
		$('.input-select-patient').show();
	}

	viewBill(id) {
		
		this.dataService.getSingleBill(id).subscribe( (data: any[] )=> {
			//@ts-ignore
			this.loadedBill = data;
			$('#modal-view-bill').modal('show');
			
		});

	}
	
	selectBill(bill) {
		this.selectedBill = bill;
	}

	submitBillCancel(data) {
		//@ts-ignore
		data.bill_id = this.selectedBill.id;

		this.dataService.postCancelBill(data).subscribe( (res:any) => {

		if(res.status == 0) {
		  alert(res.msg);
		  $('#modal-cancel-bill').modal('hide');
		  
		  this.loadBills();
		} else {
		  alert(res.msg);
		}

	  } ); 


	}

	navigateBills(direction){
		if(direction == 'right') {
			this.offset += this.limit;
		} else{
			this.offset -= this.limit;
		}

		this.dataService.localData.billingOffset = this.offset;

		this.loadBills();
	}
	
	submitReport(data){
		var key = localStorage.getItem("key");
		window.open(this.dataService.REST_API_SERVER + "reports/index&report="+data.mode+"&date1="+data.date1+"&date2="+data.date2+"&key="+key+"&dept="+data.dept);
	}

	wideSearch(){
		this.wideSearchStat = 1;
		this.loadBills();
	}

}
