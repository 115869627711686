import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demography',
  templateUrl: './demography.component.html',
  styleUrls: ['./demography.component.scss']
})
export class StatisticsDemographyComponent implements OnInit {

  constructor() { }

  	selected = 1;
  	ageData = {
		        labels: ['1-10 Years of age', '10-20 Years of age', '20-40 Years of age', '40-60 Years of age','60+ Years of age'],
		        datasets: [{
		            
		            backgroundColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A', '#43A2C9'],
		            borderColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A', '#43A2C9'],
		            data:[3200, 4300, 1760, 520, 2720],
		        }],

		    };


	genderData = {
		        labels: ['Male', 'Female', 'Unspecified'],
		        datasets: [{
		            
		            backgroundColor: ['#C93953', '#8A8643', '#7D303E'],
		            borderColor: ['#C93953', '#8A8643', '#7D303E'],
		            data:[7380, 4920, 200],
		        }],

		    };


	professionData = {
		        labels: ['Student', 'Salaried', 'Self employed', 'Unemployed'],
		        datasets: [{
		            
		            backgroundColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A'],
		            borderColor: ['#C93953', '#8A8643', '#7D303E', '#153B4A'],
		            data:[5000, 3750, 2500,1250],
		        }],

		    };

  apl_bplData = {
            labels: ['APL', 'BPL'],
            datasets: [{
                
                backgroundColor: ['#C93953', '#8A8643'],
                borderColor: ['#C93953', '#8A8643'],
                data:[5000, 3750],
            }],

        };




  ngOnInit() {
  	this.loadGraph(1);
  }

  loadGraph(val){

  	this.selected = val;

  	switch (val) {
  		case 1:
  			this.plotDemography(this.ageData);
  			break;
  		case 2:
  			this.plotDemography(this.genderData);
  			break;
  		case 3:
  			this.plotDemography(this.professionData);
  			break;
      case 4:
        this.plotDemography(this.apl_bplData);
        break;
  		
  		default:
  			this.plotDemography(this.ageData);
  			break;
  	}

  	

  }

  plotDemography(data){

  	//@ts-ignore
  	var ctx = document.getElementById('demography-graph').getContext('2d');

  	//@ts-ignore
  	if(window.bar != undefined) {
  		//@ts-ignore
  		window.bar.destroy(); 	
  	}

  	//@ts-ignore
  	window.bar = new Chart(ctx, {
	    // The type of chart we want to create
	    type: 'pie',

	    // The data for our dataset
	    data: data,

	    // Configuration options go here
	    options: {
	    	legend: {
	            display: false
	        },
          plugins: {
            datalabels: {
                  color: '#FFF'
              }
          }
	    },
      //@ts-ignore
      plugins: [ChartDataLabels],
	});

  }


}
