import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-iddsi',
  templateUrl: './iddsi.component.html',
  styleUrls: ['./iddsi.component.scss']
})
export class IddsiComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id = 0;
  data = {};
  iddsiCoords = [];

  ngOnInit() {
    this.route.parent.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

    this.loadData();
    this.retrieveCoordinates();

    let context = this;
    document.getElementById("iddsi").addEventListener('mousedown', function(e) {
      context.clickedCanvas(e);
  })

  }

  clickedCanvas(e){
    //@ts-ignore
    var rect = iddsi.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    var found = 0;

    //check if the click was around any symbols
    this.iddsiCoords.every(function(k, i){
      if(x > k.x-5 && x < k.x+20 && y > k.y-5 && y < k.y+20){
        found = k.id;
        
      }

      if(found != 0){
        return false;
      } else {
        return true;
      }
      
    });
    if(found != 0){
      //delete mark
      var cnf = confirm("Do you want to delete this mark?");
      if(cnf) {
        let data= {patient_id: this.id, found: found, type:"iddsi"};
        this.dataService.postDeleteCanvasCoords(data).subscribe( (res:any) => {
          if(res.status == 0){
            this.retrieveCoordinates();
          } else {
            alert(res.msg);
          }
          
        });
      }
    } else {
      this.showModal(e);
    }

  }

  showModal(event){
  	$('#x_val').val(event.clientX);
    $('#y_val').val(event.clientY);
    
    $('#modal-options').modal();
  }

  loadData(){
    this.dataService.getDieticsIddsi(this.id).subscribe( (data: any[] )=> {
  		this.data = data;
  		console.log('data', data);
    
  	} );
  }

  updateOption(q, val){
    this.data[q] = val;
  }

  saveForm(){
    let data = this.data;
    //@ts-ignore
    data.patient_id = this.id;

    this.dataService.postDieticsIddsi(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert(res.msg);
      } else {
        alert(res.msg);
      }


    });

  }

  insertOption(option){
  	
  	var x_val = $('#x_val').val();
    var y_val = $('#y_val').val();
    

    //@ts-ignore
    var ctx = document.getElementById("iddsi").getContext('2d');
    //@ts-ignore
    var rect = iddsi.getBoundingClientRect();
  	//@ts-ignore  
    var x = x_val - rect.left - 10;
    //@ts-ignore
    var y = y_val - rect.top-10;

    
    this.drawOption(x, y, option);
    $('#modal-options').modal('hide');

    //save to db

    let data = {
    	patient_id:this.id, 
    	x:x, 
    	y:y,
    	option:option,
    };
    
    this.dataService.postIddsiCoords(data).subscribe( (res:any) => {
      this.retrieveCoordinates();
    });

  }

  drawOption(x, y, option){
  	let image = new Image();
    image.src = (option == 0) ?  'assets/img/icon-close-red.svg' : 'assets/img/icon-check.svg'  ;
    //@ts-ignore
    var ctx = document.getElementById("iddsi").getContext('2d');
    image.onload = function(){
    	ctx.drawImage(image, x,y, 20,20);	
    }

  }

  retrieveCoordinates(){
  	//@ts-ignore
    var ctx = document.getElementById("iddsi").getContext("2d");
    //@ts-ignore
    ctx.clearRect(0,0,iddsi.width, iddsi.height);
    this.dataService.getIddsiCoords(this.id).subscribe( (data: any[] )=> {
        console.log('coords', data);
        this.iddsiCoords = data;
        let context = this;
        
        if (data.length > 0) {
        	data.forEach(function(val,i) {
        		context.drawOption(val.x, val.y, val.option);
        	})
        }

      
    } );
  }

}
