import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  	

    data:any = {address:'',city:'',po:'',district:'',pin:'',phone:'',phone2:'',gender:'',age:'',dob:'',father_name:'',mother_name:'',nationality:'',apl_bpl:'',monthly_income:'',employment:'',guardian:'',guardian_contact:'',id_type:'',id_number:'',};
    id = 0;
    role= localStorage.getItem('role_id');
	ngOnInit() {

		this.route.parent.params.subscribe((params:any) => { 
	      this.id = +params["id"];

	    });

		this.loadProfile();
	}

	loadProfile(){
		

	    this.dataService.getPatientProfile(this.id).subscribe( (data: any[] )=> {
	      this.data = data;
	      console.log(this.data);
	      
	    } );

	}

	deleteRecNote(id){
		var cnf = confirm("Are you sure you want to delete this note?");

		if (cnf) {
			var data = {id:id};
			this.dataService.postDeleteRecNote(data).subscribe( (res:any) => {
	      	if(res.status == 0) {
		        
		        alert(res.msg);
		        
		        this.router.navigate(['/dashboard/patient/'+this.id+'/details']);
		    } else {
		    	alert(res.msg);
		    }


	    });
		}

	}

}
