import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private dataService: DataService) { }

  
  data = [];
  limit = 20;
  search = "";
  showDept = 0;
  selectedUser = {};
  selectedDept = {};


  ngOnInit() {
  	this.loadUsers();
  }


  loadUsers(){
  	this.dataService.getUsers(this.limit,this.search).subscribe( (data: any[] )=> {
	  		this.data = data;
	  		//@ts-ignore
	  		if (data.role_id == 3) {
	  			this.showDept = 1;
	  		}
	  		
	  		
	  	});
  }

	loadMore(){
		this.limit +=20;
	this.loadUsers();
	}

  	searchUsers(search) {
		this.search = search;
		this.loadUsers();
	}

	deleteUser(id){

		var data = {"id":id};
		var cnf = confirm("Are you sure you want to delete this user?");
		if (cnf) {
			this.dataService.postDeleteUser(data).subscribe( (res:any) => {
		      if(res.status == 0){
		        alert(res.msg);
		        this.loadUsers();
		        
		      } else {
		        alert(res.msg);
		      }

		    });
		}

		
	}

	toggleDeptView(val){
		
		if (val == '3') {
			this.showDept = 1;
		} else {
			this.showDept = 2;
		}
	}

	submitUser(data){
		data.role = $('#userRole').val();
		data.dept = $('#userDept').val();
		
		console.log(data);

		

		$('#btn-submit-user').attr('disabled', 'true');
    	$('#btn-submit-user').text('Submitting');

    	this.dataService.postNewUser(data).subscribe( (res:any) => {
    		$('#btn-submit-user').removeAttr('disabled');
	        $('#btn-submit-user').text('Submit');
	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#modal-add-user').modal('hide');
	          this.loadUsers();
	          
	          
	        } else {
	          alert(res.msg);
	        }
	    });



	}

	loadUser(user) {
		
		
		this.dataService.getUserInfo(user.user_id).subscribe( (data: any[] )=> {
			$("#modal-edit-user").modal();
			
	    	//@ts-ignore
	      	this.selectedUser = data;
			  
	      	//@ts-ignore
	  		if (data.role_id == 3) {
	  			this.showDept = 1;
	  		}
			  
	      
	    } );
	}

	clearData(){
		this.showDept = 0;
		
	}

	updateUser(data){
		data.role = $('#editRole').val();
		data.dept = $('#editDept').val();

		$('#btn-edit-user').attr('disabled', 'true');
    	$('#btn-edit-user').text('Submitting');

    	this.dataService.postUpdateUser(data).subscribe( (res:any) => {
    		$('#btn-edit-user').removeAttr('disabled');
	        $('#btn-edit-user').text('Submit');
	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#modal-edit-user').modal('hide');
	          this.loadUsers();
	          
	          
	        } else {
	          alert(res.msg);
	        }
	    });

		
	}

	submitDept(data){
		
		$('#btn-submit-dept').attr('disabled', 'true');
    	$('#btn-submit-dept').text('Submitting');
    	
    	this.dataService.postNewDept(data).subscribe( (res:any) => {
    		$('#btn-submit-dept').removeAttr('disabled');
	        $('#btn-submit-dept').text('Submit');
	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#modal-add-dept').modal('hide');
	          this.loadUsers();
	          
	          
	        } else {
	          alert(res.msg);
	        }
	    });
	    



	}

	loadDept(dept) {
		this.selectedDept = dept;
	}

	updateDept(data) {
		$('#btn-update-dept').attr('disabled', 'true');
    	$('#btn-update-dept').text('Submitting');
    	
    	this.dataService.postUpdateDept(data).subscribe( (res:any) => {
    		$('#btn-update-dept').removeAttr('disabled');
	        $('#btn-update-dept').text('Submit');
	      	if(res.status == 0) {
	          alert(res.msg);
	          $('#modal-edit-dept').modal('hide');
	          this.loadUsers();
	          
	          
	        } else {
	          alert(res.msg);
	        }
	    });
	}

	deleteDept(id){

		var data = {"id":id};
		var cnf = confirm("Are you sure you want to delete this department?");
		if (cnf) {
			this.dataService.postDeleteDept(data).subscribe( (res:any) => {
		      if(res.status == 0){
		        alert(res.msg);
		        this.loadUsers();
		        
		      } else {
		        alert(res.msg);
		      }

		    });
		}

		
	}


}
