//@ts-nocheck
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cdcmm',
  templateUrl: './cdcmm.component.html',
  styleUrls: ['./cdcmm.component.scss']
})
export class CdcmmComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id = 0;
  type = 'cdcmm';
  data:any = {data:[]};
  ngOnInit() {

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

  	let context = this;
  	document.getElementById("lest0").addEventListener('mousedown', function(e) {
        context.clickedCanvas(e);
    })

    this.loadLest();

  }

  clickedCanvas(e){
    //@ts-ignore
    var rect = lest0.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    var found = 0;
    
    //check if the click was around any symbols
    this.data.data.every(function(k, i){
      if(x > k.x-5 && x < k.x+20 && y > k.y-5 && y < k.y+20){
        found = k.id;
        
      }
      
      if(found != 0){
        return false;
        
      } else {
        return true;
      }

    });
    if(found != 0){
      //delete mark
      var cnf = confirm("Do you want to delete this mark?");
      if(cnf) {
        let data= {patient_id: this.id, found: found, type:"lest0"};
        this.dataService.postDeleteCanvasCoords(data).subscribe( (res:any) => {
          if(res.status == 0){
            this.loadLest();
          } else {
            alert(res.msg);
          }
          
        });
      }
    } else {
      this.showModal(e);
    }

  }

  showModal(event){
  	$('#x_val').val(event.clientX);
    $('#y_val').val(event.clientY);
    
    

    $('#modal-options').modal();
  }

  insertOption(option){
  	
  	var x_val = $('#x_val').val();
    var y_val = $('#y_val').val();
    

    //@ts-ignore
    var ctx = document.getElementById("lest0").getContext('2d');
    //@ts-ignore
    var rect = lest0.getBoundingClientRect();
  	//@ts-ignore  
    var x = x_val - rect.left - 10;
    //@ts-ignore
    var y = y_val - rect.top-10;

    
    this.drawOption(x, y, option);
    $('#modal-options').modal('hide');

    //save to db

    let data = {
    	patient_id:this.id, 
    	x:x, 
    	y:y,
    	option:option,
    	type: this.type
    };

    this.data.data.push({
      x:x,
      y:y,
      option:option
    });

    this.dataService.postLestData(data).subscribe( (res:any) => {
      this.loadLest();
    });

  }

  drawOption(x, y, option){
  	let image = new Image();
    image.src = (option == 0) ?  'assets/img/icon-close-red.svg' : 'assets/img/icon-check.svg'  ;
    //@ts-ignore
    var ctx = document.getElementById("lest0").getContext('2d');
    image.onload = function(){
    	ctx.drawImage(image, x,y, 20,20);	
    }
  }



  loadLest(){
  	
  	//@ts-ignore
    var ctx = document.getElementById("lest0").getContext("2d");
    //@ts-ignore
    ctx.clearRect(0,0,lest0.width, lest0.height);

    this.dataService.getLestData(this.id, this.type, "").subscribe( (data: any[] )=> {
        
        this.data = data;
        let context = this;
        
        //@ts-ignore
        if (data.data.length > 0) {
          //@ts-ignore
        	data.data.forEach(function(val,i) {
        		context.drawOption(val.x, val.y, val.option);
        	})
        }

      
    } );

  }

  undoPrevious(){
    let data = {patient_id:this.id, type:this.type};
    this.dataService.postDeleteLastLest(data).subscribe( (res:any) => {
      
        if (res.status == 0) {
          this.loadLest();
        } else {
          alert(res.msg);  
        }

        

    });
  }

}
